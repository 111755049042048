import React, {useState, useEffect, useRef, useCallback} from 'react';
import {VendorCard} from './vendor-card';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {useGetVendorDiscoveryQuery} from '@compt/app/services/api/vendor-discovery-slice';
import {VendorDiscovery} from '@compt/types/vendor-discovery/vendor-discovery';
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';
import {Error404Page} from '../404-error-page/error-404-page';
import {DEFAULT_PAGE_LIMIT} from '@compt/constants';
import {VendorOverviewSidePanel} from '@compt/pages/vendor-discovery-page/vendor-details-side-panel';

export const VendorExplorePage: React.FC = () => {
  const [vendors, setVendors] = useState<VendorDiscovery[]>([]);
  const [offset, setOffset] = useState(0);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<VendorDiscovery | null>(null);

  const {data, error, isLoading, isFetching} = useGetVendorDiscoveryQuery({
    limit: DEFAULT_PAGE_LIMIT,
    offset,
  });

  useEffect(() => {
    if (data?.results) {
      setVendors((prevVendors) => {
        const newVendors = data.results.filter(
          (vendor) => !prevVendors.some((prev) => prev.id === vendor.id),
        );
        return [...prevVendors, ...newVendors];
      });
    }
  }, [data]);

  const loadMoreVendors = useCallback(() => {
    if (data?.next) {
      const url = new URL(data.next);
      const newOffset = parseInt(url.searchParams.get('offset') || '0');
      setOffset(newOffset);
    }
  }, [data]);

  useEffect(() => {
    if (!loadMoreRef.current || vendors.length === 0) return;
    const observer = new IntersectionObserver((entries) => {
      const [lastEntry] = entries;
      if (lastEntry.isIntersecting && !isLoading) {
        loadMoreVendors();
      }
    });
    observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [isLoading, loadMoreVendors, vendors]);

  if (error) {
    return <Error404Page />;
  }

  return (
    <ComptPage
      title="Explore"
      subtitle="Discover benefits that are most meaningful to you!"
      className="h-dvh mb-10"
      includeBottomHR={false}
    >
      <VendorOverviewSidePanel
        id="vendor-details-side-panel"
        title="Vendor overview"
        vendor={selectedVendor}
        open={!!selectedVendor}
        setOpen={setSelectedVendor}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {vendors.map((vendor, index) => (
          <div
            key={`${vendor.id}-${index}`}
            className="relative mt-16"
            onClick={() => {
              setSelectedVendor(vendor);
            }}
          >
            <VendorCard
              logoSrc={vendor.logo_image}
              title={vendor.name}
              offer={vendor.offer}
              description={vendor.short_description}
              perkCategories={vendor.perk_categories}
            />
          </div>
        ))}
      </div>
      <div ref={loadMoreRef}>
        {(isLoading || isFetching) && (
          <div className="flex flex-col w-full mt-6">
            <div className="mb-[9px]">
              <ComptLoadingAnimation />
            </div>
            <p className="label3 text-color-body1 -mt-7 mx-auto">Loading vendors...</p>
          </div>
        )}
      </div>
    </ComptPage>
  );
};
