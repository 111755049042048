import React, {Dispatch, SetStateAction, useMemo} from 'react';

// RTK queries
import {useGetStipendsQuery} from '@compt/app/services/api/admin-stipends-slice';
import {useUpdateProgramFundingRuleMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptGroupTable} from '@compt/common/compt-group-table/compt-group-table';
import {GroupTableHeaders} from '@compt/common/compt-group-table/compt-group-table.headers';

// Types
import {OptionType} from '@compt/types/user-groups/user-groups';
import {EligibilityBreakdownOption} from '@compt/types/learning-development/eligibility-breakdown.types';
import {
  LearningDevelopmentProgram,
  ProgramFundingRulePayload,
} from '@compt/types/learning-development/learning-development-program';
import {GroupTableFieldValues} from '@compt/common/compt-group-table/compt-group-table.types';

interface BudgetPlanSidePanelProps {
  setExistingData: Dispatch<SetStateAction<LearningDevelopmentProgram | null>>;
  eligibility: EligibilityBreakdownOption;
  existingData: LearningDevelopmentProgram | null;
}

export const BudgetPlanSidePanel = (props: BudgetPlanSidePanelProps) => {
  const {existingData} = props;

  const stipendsQuery = useGetStipendsQuery();

  const eligibilityAmountHeaderMap = {
    [EligibilityBreakdownOption.SHARED_BUDGET]: 'Amount per group',
    [EligibilityBreakdownOption.INDIVIDUAL_BUDGET]: 'Amount per person',
    [EligibilityBreakdownOption.CASE_BY_CASE]: '',
  };

  const title =
    props.eligibility === EligibilityBreakdownOption.CASE_BY_CASE
      ? 'Plan your employee eligibility'
      : 'Plan your budget';

  const amountHeader = eligibilityAmountHeaderMap[props.eligibility];

  const existingMultiGroupKeys = useMemo(() => {
    if (!existingData) return [];
    return Object.keys(
      existingData.program_funding_rules[0]?.user_grouping_rule?.user_properties || {},
    );
  }, [existingData]);
  const userGroupTypeOptions = stipendsQuery.data?.userGroupTypes
    ? [
        ...stipendsQuery.data.userGroupTypes,
        {
          key: 'everyone',
          header: 'Everyone',
          type: OptionType.EVERYONE,
        },
      ]
    : [
        {
          key: 'everyone',
          header: 'Everyone',
          type: OptionType.EVERYONE,
        },
      ];

  const [updateProgramFundingRule, {isLoading: isUpdating}] = useUpdateProgramFundingRuleMutation();

  const formMethods = useForm<GroupTableFieldValues>();

  function onSubmit(form: GroupTableFieldValues) {
    if (!existingData) return;

    let submissionBody = [] as ProgramFundingRulePayload[];

    // Handle case where "Everyone" option is selected
    if (
      form.amountOverrides.length === 1 &&
      Object.keys(form.amountOverrides[0].overrides).length === 0
    ) {
      const amount =
        props.eligibility === EligibilityBreakdownOption.CASE_BY_CASE
          ? 1
          : form.amountOverrides[0].amount;
      submissionBody = [
        {
          amount: amount,
          currency: form.amountOverrides[0].currency,
          user_grouping_rule: {},
          sequence: 0,
        },
      ];
    } else {
      submissionBody = form.amountOverrides.map((override) => {
        const userProperties = {} as {[key: string]: string};

        Object.values(override.overrides).forEach((item) => {
          userProperties[item.groupType] = item.key;
        });

        // Maintain same key order for every user grouping rule to avoid mismatch
        const sortedKeys = Object.keys(userProperties).sort();
        const sortedUserProperties = {} as {[key: string]: string};

        for (const key of sortedKeys) {
          sortedUserProperties[key] = userProperties[key];
        }

        const amount =
          props.eligibility === EligibilityBreakdownOption.CASE_BY_CASE ? 1 : override.amount;

        return {
          amount: amount,
          currency: override.currency,
          user_grouping_rule: {user_properties: sortedUserProperties},
          sequence: 0,
        };
      });
    }

    updateProgramFundingRule({
      body: submissionBody,
      programId: existingData.id,
      companyId: existingData.company.id,
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'There was a problem updating your budget plan');
        return;
      }

      props.setExistingData(null);
    });
  }

  return (
    <ComptSidePanel open={!!existingData} className="max-w-full">
      <ComptSidePanel.Header
        title={title}
        setOpen={() => props.setExistingData(null)}
        headerIcon={{id: 'file-icon-blue'}}
      />
      <ComptSidePanel.Content>
        <div className="px-6 pt-6">
          <ComptGroupTable
            formMethods={formMethods}
            userGroups={stipendsQuery.data?.userGroups}
            groupTypeOptions={userGroupTypeOptions}
            amountHeader={amountHeader}
            existingAmountOverrides={existingData?.program_funding_rules}
            existingMultiGroupKeys={existingMultiGroupKeys}
            initializeEveryoneOption
            hideAmountFields={props.eligibility === EligibilityBreakdownOption.CASE_BY_CASE}
          >
            <GroupTableHeaders amountHeader={amountHeader} />
          </ComptGroupTable>
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <ComptButton onClick={formMethods.handleSubmit(onSubmit)} disabled={isUpdating}>
          Save
        </ComptButton>
        <ComptButton
          buttonType={ComptButtonType.BORDERLESS}
          onClick={() => props.setExistingData(null)}
          disabled={isUpdating}
        >
          Cancel
        </ComptButton>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
