import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {
  SaveClaimFormFieldValues,
  SaveClaimSidePanelController,
} from './compt-save-claim-side-panel.controller';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {ComptFormDivider} from '@compt/common/forms/compt-form-divider/compt-form-divider';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {getUTCDateFromString} from '@compt/utils/date-helpers';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {Allotment} from '@compt/types/allotments';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

interface StipendFormContentProps {
  formMethods: UseFormReturn<SaveClaimFormFieldValues>;
  allotments: Allotment[] | undefined;
  userCountryCode?: SupportedCountriesType;
  stipendCurrency?: string;
  disabled?: boolean;
  editMode?: boolean;
}

export const StipendFormContent = (props: StipendFormContentProps) => {
  const controller = SaveClaimSidePanelController;

  const {formMethods} = props;

  const watchedAllotment = props.formMethods.watch('allotment');
  const watchedCategory = props.formMethods.watch('perk_category');
  const watchedVendor = props.formMethods.watch('vendor');
  const watchedDescription = props.formMethods.watch('description');

  const perkCategoryOptions = controller.getSelectableCategories(props.allotments);
  const allotmentOptions = controller.sortAllotmentsByName(
    controller.getFilteredAllotments(props.allotments, watchedCategory) || [],
  );

  return (
    <>
      <ComptDropDownField
        name="perk_category"
        data-testid="compt-save-claim-perk-category-dropdown"
        label="Category"
        placeholder="Choose Category"
        options={perkCategoryOptions}
        getKey={(option) => option.id}
        getDisplayText={(option) => option.name}
        validation={{required: true}}
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors.perk_category}
        disabled={props.disabled}
        readOnly={!props.editMode}
        by="id"
      />
      <ComptDropDownField
        name="allotment"
        label="Available stipend(s)"
        data-testid="compt-save-claim-allotment-dropdown"
        placeholder="Choose Stipend"
        options={allotmentOptions}
        getKey={(option) => option.id}
        getDisplayText={(option) => option.cycle.name}
        validation={{required: true}}
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors.allotment}
        disabled={props.disabled}
        readOnly={!props.editMode}
        by={(a, b) => a?.id === b?.id}
      />
      <ComptFormDivider />
      <ComptDatePickerField
        name="date_of_expense"
        label="Receipt date"
        control={formMethods.control}
        register={formMethods.register}
        validation={{required: true}}
        errors={formMethods.formState.errors.date_of_expense}
        disabled={props.disabled || !watchedAllotment}
        readOnly={!props.editMode}
        startDate={getUTCDateFromString(watchedAllotment?.earliest_receipt_date) || null}
      />
      <ComptFormDivider />
      <ComptTextField
        name="vendor"
        label="Vendor"
        control={formMethods.control}
        register={formMethods.register}
        validation={{
          required: true,
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Vendor has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        disabled={props.disabled}
        readOnly={!props.editMode}
        errors={formMethods.formState.errors.vendor}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedVendor}
      />
      <ComptTextAreaField
        name="description"
        label="Description"
        placeholder="Describe the claim purchase"
        validation={{
          required: true,
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Description has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        register={formMethods.register}
        disabled={props.disabled}
        readOnly={!props.editMode}
        errors={formMethods.formState.errors.description}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedDescription}
      />
      <ComptCurrencyField
        name="amount_of_expense"
        label={props.editMode ? 'Amount entered' : 'Amount'}
        control={formMethods.control}
        register={formMethods.register}
        errors={formMethods.formState.errors.amount_of_expense}
        validation={{
          required: true,
          min: 0.01,
        }}
        placeholder="0.00"
        defaultCurrencyCode={props.userCountryCode}
        givenCurrency={watchedAllotment?.currency || props.stipendCurrency}
        disabled={props.disabled}
        readOnly={!props.editMode}
      />
    </>
  );
};
