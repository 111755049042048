import React, {useEffect, useMemo, useRef, useState} from 'react';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {LndFormController} from './learning-development-form.controller';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetLearningDevelopmentProgramQuery} from '@compt/app/services/api/learning-development-slice';

// Types
import {LnDProgramForms} from './learning-development.types';
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {CreateProgramOverview} from './components/program-overview/program-overview-create';
import {UpdateProgramOverview} from './components/program-overview/program-overview-update';
import {EligibilityBreakdownForm} from './components/eligibility-breakdown/eligibility-breakdown';
import {FundsExpirationForm} from './components/funds-expiration';
import {FormStep, FormStepper} from '@compt/common/compt-form-stepper/compt-form-stepper';
import {ApproverReviewerForm} from '@compt/pages/admin-pages/admin-learning-development/components/approver-reviewer/approver-reviewer';
import {ProgramCreationPreApprovalRequestForm} from '@compt/pages/admin-pages/admin-learning-development/components/program-creation-pre-approval-request-form';
import {ProgramCreationReimbursementRequestForm} from '@compt/pages/admin-pages/admin-learning-development/components/program-creation-reimbursement-request-form';
import {ReviewForm} from './components/review-form/program-creation-review-form';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

const initialStepStatus = {
  [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.CURRENT,
  [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.LOCKED,
  [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.LOCKED,
  [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.LOCKED,
  [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.LOCKED,
  [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.LOCKED,
  [LnDProgramForms.REVIEW]: StepStatus.LOCKED,
};

const controller = LndFormController;

export const LearningDevelopmentFormPage = () => {
  const [stepStatusMap, setStepStatusMap] = useState<{[key: string]: StepStatus}>(
    initialStepStatus,
  );

  const [searchParams] = useSearchParams();

  const formType = searchParams.get('form_type');
  const programId = searchParams.get('program_id');

  const isCreateForm = formType === 'create';
  const isEditForm = formType === 'edit';

  const stepStatusInitialized = useRef(false);

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);
  const {data: programData, isLoading: programDataLoading} = useGetLearningDevelopmentProgramQuery(
    {companyId: companyQuery.data?.id, programId: programId},
    {skip: !companyQuery.data?.id || !programId},
  );

  const readOnly = (!!programData && !programData?.is_draft) || false;

  const formStepStatusBase = useMemo(
    () => ({
      [LnDProgramForms.PROGRAM_OVERVIEW]: controller.getFormStatus(
        controller.isProgramOverviewComplete(programData),
        true,
        readOnly,
      ),
      [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: controller.getFormStatus(
        controller.isEligibilityBreakdownComplete(programData),
        controller.isProgramOverviewComplete(programData),
        readOnly,
      ),
      [LnDProgramForms.FUNDS_EXPIRATION]: controller.getFormStatus(
        controller.isFundingExpirationComplete(programData),
        controller.isEligibilityBreakdownComplete(programData),
        readOnly,
      ),
      [LnDProgramForms.PRE_APPROVAL_REQUEST]: controller.getFormStatus(
        controller.isPreApprovalRequestComplete(programData),
        controller.isFundingExpirationComplete(programData),
        readOnly,
      ),
      [LnDProgramForms.REIMBURSEMENT_REQUEST]: controller.getFormStatus(
        controller.isReimbursementRequestComplete(programData),
        controller.isPreApprovalRequestComplete(programData),
        readOnly,
      ),
      [LnDProgramForms.APPROVER_REVIEWER]: controller.getFormStatus(
        controller.isApproverReviewerComplete(programData),
        controller.isReimbursementRequestComplete(programData),
        readOnly,
      ),
      [LnDProgramForms.REVIEW]: controller.getReviewerStepStatus(programData, readOnly),
    }),
    [programData],
  );

  // Initialize steps for forms with existing data
  useEffect(() => {
    if (stepStatusInitialized.current || !programData || !isEditForm) return;

    // Navigate only to review form if program is launched or scheduled to launch
    if (!programData.is_draft) {
      setStepStatusMap({
        ...formStepStatusBase,
        [LnDProgramForms.REVIEW]: StepStatus.CURRENT,
      });
      return;
    }
    setStepStatusMap({
      ...formStepStatusBase,
      [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.CURRENT,
    });

    stepStatusInitialized.current = true;
  }, [programData]);

  const progressSteps = useMemo(
    () => [
      {
        name: 'Program overview',
        status: stepStatusMap[LnDProgramForms.PROGRAM_OVERVIEW],
        statusBase: formStepStatusBase[LnDProgramForms.PROGRAM_OVERVIEW],
        onClick: () => {
          setStepStatusMap({
            ...formStepStatusBase,
            [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.CURRENT,
          });
        },
      },
      {
        name: 'Eligibility breakdown',
        status: stepStatusMap[LnDProgramForms.ELIGIBILITY_BREAKDOWN],
        statusBase: formStepStatusBase[LnDProgramForms.ELIGIBILITY_BREAKDOWN],
        onClick: () => {
          setStepStatusMap({
            ...formStepStatusBase,
            [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
          });
        },
      },
      {
        name: 'Funding expiration',
        status: stepStatusMap[LnDProgramForms.FUNDS_EXPIRATION],
        statusBase: formStepStatusBase[LnDProgramForms.FUNDS_EXPIRATION],
        onClick: () => {
          setStepStatusMap({
            ...formStepStatusBase,
            [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.CURRENT,
          });
        },
      },
      // TODO (COMPT-5630): Re-enable when we implement custom fields steps
      // {
      //   name: 'Pre-approval request form',
      //   status: stepStatusMap[LnDProgramForms.PRE_APPROVAL_REQUEST],
      //   statusBase: formStepStatusBase[LnDProgramForms.PRE_APPROVAL_REQUEST],
      //   onClick: () => {
      //     setStepStatusMap({
      //       ...formStepStatusBase,
      //       [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.CURRENT,
      //     });
      //   },
      // },
      // {
      //   name: 'Reimbursement request form',
      //   status: stepStatusMap[LnDProgramForms.REIMBURSEMENT_REQUEST],
      //   statusBase: formStepStatusBase[LnDProgramForms.REIMBURSEMENT_REQUEST],
      //   onClick: () => {
      //     setStepStatusMap({
      //       ...formStepStatusBase,
      //       [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.CURRENT,
      //     });
      //   },
      // },
      {
        name: 'Approvers and reviewer',
        status: stepStatusMap[LnDProgramForms.APPROVER_REVIEWER],
        statusBase: formStepStatusBase[LnDProgramForms.APPROVER_REVIEWER],
        onClick: () => {
          setStepStatusMap({
            ...formStepStatusBase,
            [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
          });
        },
      },
      {
        name: 'Review',
        status: stepStatusMap[LnDProgramForms.REVIEW],
        statusBase: formStepStatusBase[LnDProgramForms.REVIEW],
        onClick: () => {
          setStepStatusMap({
            ...formStepStatusBase,
            [LnDProgramForms.REVIEW]: StepStatus.CURRENT,
          });
        },
      },
    ],
    [stepStatusMap, formStepStatusBase],
  );

  if (companyQuery.isLoading || programDataLoading)
    return <ComptLoadingIndicator isLoading={true} />;

  if (!companyQuery.data) return <Error404Page />;

  return (
    <ComptPage
      title="Create a professional development program"
      subtitle="Complete the form to allow employees to submit a request for funds."
      includeBottomHR={false}
      className="sm:mx-0 my-0 py-6 h-full"
      headerClassName="mx-10"
    >
      <FormStepper
        stepStatusMap={stepStatusMap}
        setStepStatusMap={setStepStatusMap}
        progressSteps={progressSteps}
        stepStatusBase={formStepStatusBase}
      >
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.PROGRAM_OVERVIEW]}>
          {isCreateForm && <CreateProgramOverview companyId={companyQuery.data.id} />}
          {programData && <UpdateProgramOverview existingData={programData} readOnly={readOnly} />}
        </FormStep>
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.ELIGIBILITY_BREAKDOWN]}>
          {programData && (
            <EligibilityBreakdownForm existingData={programData} readOnly={readOnly} />
          )}
        </FormStep>
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.FUNDS_EXPIRATION]}>
          {programData && <FundsExpirationForm existingData={programData} readOnly={readOnly} />}
        </FormStep>
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.PRE_APPROVAL_REQUEST]}>
          {programData && (
            <ProgramCreationPreApprovalRequestForm existingData={programData} readOnly={readOnly} />
          )}
        </FormStep>
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.REIMBURSEMENT_REQUEST]}>
          {programData && (
            <ProgramCreationReimbursementRequestForm
              existingData={programData}
              readOnly={readOnly}
            />
          )}
        </FormStep>
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.APPROVER_REVIEWER]}>
          {programData && <ApproverReviewerForm existingData={programData} readOnly={readOnly} />}
        </FormStep>
        <FormStep stepStatus={stepStatusMap[LnDProgramForms.REVIEW]}>
          {programData && <ReviewForm existingData={programData} readOnly={readOnly} />}
        </FormStep>
      </FormStepper>
    </ComptPage>
  );
};
