import React from 'react';

// Components
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

// Types
import {UseFormReturn} from 'react-hook-form';

interface AdminPerDiemBEFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any, any, undefined>;
  isReadOnly: boolean;
}

export const AdminPerDiemExpenseFields = ({formMethods, isReadOnly}: AdminPerDiemBEFieldProps) => (
  <>
    <ComptDatePickerField
      name="start_date"
      label="Start date"
      data-testid="start-date-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Start date is required'}}
      errors={formMethods.formState.errors.start_date}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptDatePickerField
      name="end_date"
      label="End date"
      data-testid="end-date-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'End date is required'}}
      errors={formMethods.formState.errors.end_date}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptTextField
      name="origin"
      label="Origin"
      data-testid="origin-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Origin is required'}}
      errors={formMethods.formState.errors.origin}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptTextField
      name="destination"
      label="Destination"
      data-testid="destination-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Destination is required'}}
      errors={formMethods.formState.errors.destination}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptCurrencyField
      name="per_diem_rate"
      label="Per diem rate"
      data-testid="per-diem-rate-field"
      control={formMethods.control}
      register={formMethods.register}
      errors={formMethods.formState.errors.per_diem_rate}
      validation={{
        required: 'Per diem rate is required',
        min: 0.01,
      }}
      placeholder="0.00"
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
  </>
);
