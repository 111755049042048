import React from 'react';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAllEmployeeProgramBudgetsQuery} from '@compt/app/services/api/employee-learning-development-slice';

// Components
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {NoPermissionsPage} from '../no-permissions-page/no-permissions-page';
import {Error500Page} from '../500-error-page/error-500-page';
import {CommunityFeedSidebar} from './components/community-feed-sidebar';
import {CommunityActivityFeed} from './components/community-activity-feed';

export const CommunityPage = () => {
  const isMobileView = useIsMobileView();

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);

  const employeePrograms = useGetAllEmployeeProgramBudgetsQuery(
    companyQuery?.data?.id ?? skipToken,
  );

  if (!featureEnabled(FeatureFlags.COMMUNITY_FEED)) return <></>;

  if (isMobileView) {
    return (
      <NoPermissionsPage
        heading="This page is not yet formatted for mobile view"
        description="Please access this page from a desktop view!"
      />
    );
  }

  if (sessionQuery.isError || companyQuery.isError || employeePrograms.isError)
    return <Error500Page />;

  return (
    <div className="flex h-full">
      <ComptPage
        title="Community"
        subtitle="See what others in the Compt network are learning."
        className="grow px-600 pt-6 h-full overflow-y-auto"
        includeBottomHR={false}
        useDefaultMargins={false}
      >
        <ComptLoadingIndicator isLoading={companyQuery.isLoading}>
          {sessionQuery.data && companyQuery.data && <CommunityActivityFeed />}
        </ComptLoadingIndicator>
      </ComptPage>
      <div className="w-[320px] min-w-[320px] border-l border-stroke-divider1 h-full overflow-scroll">
        <CommunityFeedSidebar />
      </div>
    </div>
  );
};
