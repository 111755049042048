import React from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Types
import {DateString} from '@compt/types/common/date-string';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {MessageType} from '@compt/types/comments/comment';
import {ComptPill} from '../forms/compt-pill/compt-pill';
import {LearningStatusFormats} from '@compt/pages/admin-pages/admin-review-lnd/review-lnd.types';

interface ComptCommentProps {
  name: string;
  date: DateString;
  message: string | null;
  messageType: MessageType;
  status: LearningRequestStatus | null;
}

export const ComptReceivedComment = (props: ComptCommentProps) => (
  <div className="flex flex-col justify-start w-full">
    <span className="flex gap-x-2 mb-2">
      <p className="label3">{props.name}</p>
      <p className="body4 text-color-body2 mt-0.5">{formatDateString(props.date)}</p>
    </span>
    <div
      className={'border w-fit px-4 py-2 rounded-tr-lg rounded-br-lg rounded-bl-lg bg-[#FAF6F1]'}
      style={{maxWidth: '90%'}}
    >
      {renderContent(props.messageType, props.message, props.status)}
    </div>
  </div>
);

export const ComptSentComment = (props: ComptCommentProps) => (
  <div className="flex flex-col w-full items-end">
    <span className="flex gap-x-2 mb-2">
      <p className="label3">You</p>
      <p className="body4 text-color-body2 mt-0.5">{formatDateString(props.date)}</p>
    </span>
    <div
      className={'border w-fit px-4 py-2 rounded-tl-lg rounded-br-lg rounded-bl-lg bg-brand-900'}
      style={{maxWidth: '90%'}}
    >
      {renderContent(props.messageType, props.message, props.status, 'text-color-light')}
    </div>
  </div>
);

function formatDateString(isoString: DateString) {
  const date = new Date(isoString);

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayName = days[date.getDay()];

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const isPM = hours >= 12;
  hours = hours % 12 || 12;

  const amPm = isPM ? 'pm' : 'am';

  return `${dayName} ${hours}:${minutes}${amPm}`;
}

function renderContent(
  messageType: MessageType,
  message: string | null,
  status: LearningRequestStatus | null,
  textClassName?: string,
) {
  if (messageType === MessageType.USER_COMMENT && message) {
    return <p className={twMerge(`body1 ${textClassName}`)}>{message}</p>;
  }

  if (messageType === MessageType.STATUS_CHANGE && status) {
    const requestStatusFormat = LearningStatusFormats[status as LearningRequestStatus];

    return (
      <span className="flex flex-col sm:flex-row gap-x-2">
        <p className={twMerge(`body1 ${textClassName}`)}>Program status updated to</p>
        <ComptPill className="w-fit" pillType={requestStatusFormat?.statusStyle}>
          {requestStatusFormat?.label}
        </ComptPill>
      </span>
    );
  }
}
