import React from 'react';

// RTK queries
import {useGetEmployeePreApprovalRequestQuery} from '@compt/app/services/api/employee-learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';

// Types
import {ReviewFieldValues} from './review-field-values.types';

// Components
import {ReviewForm} from './review-form';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

interface ReviewFormSidePanelProps {
  reviewRequestId: string | null;
  companyId: number | undefined;
  'data-testid'?: string;
}

export const ReviewFormSidePanel = (props: ReviewFormSidePanelProps) => {
  const [, setSearchParams] = useSearchParams();

  const preApprovalRequestQuery = useGetEmployeePreApprovalRequestQuery(
    {
      companyId: props.companyId,
      requestId: props.reviewRequestId,
    },
    {skip: !props.companyId || !props.reviewRequestId},
  );

  const formMethods = useForm<ReviewFieldValues>();

  function onSubmit() {
    // TODO: define this function in COMPT-6069
  }

  function onCancel() {
    formMethods.reset();
    setSearchParams({});
  }

  return (
    <ComptSidePanel
      open={!!props.reviewRequestId}
      className="max-w-[520px]"
      data-testid={props['data-testid']}
    >
      <ComptSidePanel.Header
        title="Reimbursement form"
        subtitle={preApprovalRequestQuery?.data?.program.name}
        setOpen={onCancel}
        headerIcon={{id: 'file-icon-blue'}}
        breadCrumbs={['Reimbursement request', 'Your review (optional)']}
      />
      <ComptSidePanel.Content className="sm:overflow-y-auto p-6">
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <p className="label2 text-color-heading">Your review</p>
          <p className="body3 text-color-body2 mb-6">
            Review your experience with the purchase. This will be shared publicly on the Community
            page. Responses are anonymous.
          </p>
          <ReviewForm formMethods={formMethods} />
          <p className="label2 text-color-heading">Show your LinkedIn profile</p>
          <p className="body3 text-color-body2 mb-6">
            Add your LinkedIn URL if you would like your profile to be visible to other Compt users
            (across companies) on the Community feed.
          </p>
          <ComptTextField name="linkedin_url" label="LinkedIn URL" placeholder="https://" />
          <p className="body3 text-color-body2 -mt-3">
            By adding your LinkedIn URL, your review will no longer be anonymous.
          </p>
        </form>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex">
          <ComptButton type="submit" onClick={formMethods.handleSubmit(onSubmit)}>
            Submit
          </ComptButton>
          <ComptButton buttonType={ComptButtonType.BORDERLESS} onClick={onCancel}>
            Skip
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
