import React, {useState, useRef} from 'react';

// Types
import {DevelopmentCourseReview} from '@compt/types/community/common-types';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptTooltip} from '../compt-tooltip/compt-tooltip.container';
import {ComptTooltipPlacement} from '../compt-tooltip/compt-tooltip.controller';

interface ComptCommunityCardProps {
  review: DevelopmentCourseReview;
}

export const ComptCommunityCard = ({review}: ComptCommunityCardProps) => {
  const [openReactionModal, setOpenReactionModal] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {linkedin_account, job_title, industry} = review.author || {};
  const {
    course_rating,
    review_title,
    review_description,
    reactions: {total_reactions},
    perk_categories,
  } = review.programReview || {};

  const clearTimer = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
  };

  const handleMouseEnter = () => {
    // Clear any pending timeouts
    clearTimer();
    setOpenReactionModal(true);
  };

  const handleMouseLeave = () => {
    clearTimer();
    hoverTimeoutRef.current = setTimeout(() => {
      setOpenReactionModal(false);
    }, 300);
  };

  const handleReactionClick = (reaction: string) => {
    // eslint-disable-next-line no-console
    console.log(`Reaction selected: ${reaction}`);
    handleMouseLeave();
  };

  return (
    <div className="bg-surface-tint p-[12px] rounded-xl border border-stroke-divider-1">
      <div className="flex">
        <div
          className="flex items-center justify-center bg-white rounded-full h-[40px] w-[40px] 
        mr-4 border border-stroke-divider-1 flex-shrink-0"
        >
          <ComptSvgIcon
            iconName={`rating-${course_rating}`}
            svgProp={{
              width: '20px',
              height: '20px',
            }}
          />
        </div>
        <div className="w-full">
          <div className="flex gap-1">
            <p className="label4">{`${job_title || 'Someone'} in ${industry}`}</p>
            {linkedin_account ? (
              <ComptSvgIcon
                iconName={'linkedin-icon'}
                svgProp={{
                  width: '16px',
                  height: '16px',
                }}
              />
            ) : null}
          </div>
          <div className="flex items-center gap-1">
            <p className="label3 text-color-link ">{review_title}</p>
            {perk_categories && perk_categories.length > 0 && (
              <ComptTooltip
                placement={ComptTooltipPlacement.BOTTOM}
                message={
                  <div className="compt-text--xs text-white">
                    {perk_categories?.map((category, index) => <p key={index}>{category.name}</p>)}
                  </div>
                }
              >
                <div className="flex -space-x-2">
                  {perk_categories.map(({internal_id, id}) => (
                    <ComptSvgIcon
                      key={id}
                      iconName={internal_id}
                      isCategoryIcon
                      svgProp={{width: '20px', height: '20px'}}
                      className="p-1 h-6 w-6 border border-stroke-divider-1  bg-[white] rounded-full
            flex items-center justify-center"
                    />
                  ))}
                </div>
              </ComptTooltip>
            )}
          </div>
          <div className="flex w-full relative">
            <div
              className="flex flex-col w-full bg-white mt-2 border border-stroke-divider-1
                rounded-xl p-4 mr-2 "
            >
              {course_rating === 5 && (
                <p className="label4 text-color-body1 mb-2">Highly recommended</p>
              )}
              <p className="body3 text-color-body1">{`"${review_description}"`}</p>
            </div>
            <div
              className={`relative flex flex-col items-center justify-center mt-2 border 
                  border-stroke-divider-1 rounded-lg p-4 w-[60px] ${
                    openReactionModal ? 'bg-surface-secondary-hover' : 'bg-white'
                  }`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {openReactionModal && (
                <div
                  className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 flex
                  bg-white border border-stroke-divider-1 rounded-full p-1 gap-1 shadow-lg"
                >
                  {['like', 'love', 'insightful'].map((reaction) => (
                    <button
                      key={reaction}
                      className="reaction-button"
                      onClick={() => handleReactionClick(reaction)}
                      aria-label={`React with ${reaction}`}
                    >
                      <ComptSvgIcon
                        iconName={`${reaction}-icon`}
                        svgProp={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </button>
                  ))}
                </div>
              )}

              <ComptSvgIcon iconName={'chevron-up-icon-filled'} />
              <p className="label3 text-color-black">{total_reactions}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
