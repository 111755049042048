import React, {useState} from 'react';

// Hooks and methods
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldPathByValue,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';

// Types
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';

export interface ComptKeywordInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  'data-testid'?: string;
  name: TName;
  label?: string;
  helperText?: string;
  errors: FieldValues | undefined;
  setError: UseFormSetError<TFieldValues>;
  clearError: UseFormClearErrors<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  disabled?: boolean;
  control?: Control<TFieldValues>;
}

export const ComptKeywordInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptKeywordInputProps<TFieldValues, TName>,
) => {
  const [currentInput, setCurrentInput] = useState<string>('');

  const handleAddKeyword = (field: ControllerRenderProps<TFieldValues, TName>) => {
    const trimmedInput = currentInput.trim();
    if (!trimmedInput) {
      props.setError(props.name, {type: 'manual', message: 'Please enter a keyword'});
      return;
    }

    // Split by commas and trim each keyword
    const newKeywords = trimmedInput.split(',').map((keyword) => keyword.trim());
    const currentKeywords = props.getValues(props.name) || [];

    const validKeywords = newKeywords.filter((keyword) => {
      if (!keyword) {
        props.setError(props.name, {type: 'manual', message: 'Empty keywords are not allowed'});
        return false;
      }
      if (
        currentKeywords.some((existing: string) => existing.toLowerCase() === keyword.toLowerCase())
      ) {
        props.setError(props.name, {type: 'manual', message: `Duplicate keyword: "${keyword}"`});
        return false;
      }
      return true;
    });

    if (validKeywords.length > 0) {
      const updatedKeywords = [...currentKeywords, ...validKeywords];
      field.onChange(updatedKeywords);
      setCurrentInput('');
      props.clearError(props.name);
    }
  };

  const handleRemoveKeyword = (
    keywordToRemove: string,
    field: ControllerRenderProps<TFieldValues, TName>,
  ) => {
    const updatedKeywords = props
      .getValues(props.name)
      .filter((keyword: string) => keyword.toLowerCase() !== keywordToRemove.toLowerCase());
    field.onChange(updatedKeywords);
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <ComptFormField
          id={props.name as string}
          field={
            <div>
              <input
                type="text"
                value={currentInput}
                placeholder={props.placeholder || 'Type a keyword and press Enter'}
                disabled={props.disabled}
                onChange={(e) => setCurrentInput(e.target.value)}
                onBlur={() => handleAddKeyword(field)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddKeyword(field);
                  }
                }}
                className={twMerge(
                  `block w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 
                   ring-inset focus:ring-2 body1 text-color-body2
                   placeholder:text-gray-400
                  ${
                    props.errors
                      ? 'ring-stroke-critical focus:ring-stroke-critical'
                      : 'ring-stroke-tertiary focus:ring-stroke-focus'
                  }`,
                )}
              />
              {props.helperText && (
                <p className="body3 text-color-body2 mt-1 mb-3">{props.helperText}</p>
              )}
              <div className="mt-2 flex flex-wrap gap-2">
                {props.getValues(props.name)?.map((keyword: string) => (
                  <div
                    key={keyword}
                    className="inline-flex items-center bg-gray-100 text-gray-700 px-2 
                    py-1 rounded-full text-sm border border-gray-300"
                  >
                    {keyword}
                    {!props.disabled && (
                      <button
                        type="button"
                        className="ml-2 text-gray-500 hover:text-gray-700"
                        onClick={() => handleRemoveKeyword(keyword, field)}
                        aria-label={`Remove keyword ${keyword}`}
                      >
                        <ComptSvgIcon iconName="x-icon" svgProp={{width: '12px', height: '12px'}} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          }
          label={props.label}
          subLabel={props.subLabel}
          validation={props.validation}
          errors={props.errors}
          data-testid={props['data-testid']}
        />
      )}
    />
  );
};
