import React, {useState} from 'react';
import {ChangePasswordModal} from '../compt-top-bar/change-password-modal';
import {UserAccountSettingsModal} from '../compt-top-bar/user-accounts-settings-modal';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptButtonIcon} from '../compt-button/compt-button';
import {ComptHelpScoutLink} from '@compt/common/compt-helpscout-link/compt-helpscout-link';

export type ComptAccountSettingSectionProps = {
  hasNavSections: boolean;
};

export const AccountSettingSection = (props: ComptAccountSettingSectionProps) => {
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openUserAccountSettingsModal, setOpenUserAccountSettingsModal] = useState(false);

  return (
    <>
      <hr className="border my-1" />
      <ChangePasswordModal open={openChangePasswordModal} setOpen={setOpenChangePasswordModal} />
      <UserAccountSettingsModal
        open={openUserAccountSettingsModal}
        setOpen={setOpenUserAccountSettingsModal}
      />
      <div className="grid gap-y-1">
        <button type="button" onClick={() => setOpenUserAccountSettingsModal(true)}>
          <div className="flex px-3 py-2">
            <ComptSvgIcon iconName={ComptButtonIcon.USER} svgProp={{height: 25, width: 24}} />
            <p className="body2 text-color-body1 ml-2">My account</p>
          </div>
        </button>
        {props.hasNavSections && (
          <div className="grid gap-y-1">
            <button type="button" onClick={() => setOpenChangePasswordModal(true)}>
              <div className="flex px-3 py-2">
                <ComptSvgIcon
                  iconName={ComptButtonIcon.PASSWORD_LOCK}
                  svgProp={{height: 25, width: 24}}
                />
                <p className="body2 text-color-body1 ml-2">Change password</p>
              </div>
            </button>
            <a href="/terms/" target="_blank">
              <div className="flex px-3 py-2">
                <ComptSvgIcon iconName="file-icon" svgProp={{height: 25, width: 24}} />
                <p className="body2 text-color-body1 ml-2">Terms of use</p>
              </div>
            </a>
            <a href="https://www.compt.io/compt-privacy-policy" target="_blank">
              <div className="flex px-3 py-2">
                <ComptSvgIcon iconName="file-lock-icon" svgProp={{height: 25, width: 24}} />
                <p className="body2 text-color-body1 ml-2">Privacy policy</p>
              </div>
            </a>
            <button onClick={window.displayPreferenceModal}>
              <div className="flex px-3 py-2">
                <ComptSvgIcon iconName="settings-slider-icon" svgProp={{height: 25, width: 24}} />
                <p className="body2 text-color-body1 ml-2">Cookie preferences</p>
              </div>
            </button>
            <ComptHelpScoutLink />
          </div>
        )}
      </div>
      <hr className="border my-1" />
      <a className="" href="/accounts/logout">
        <div className="flex px-3 py-2">
          <ComptSvgIcon iconName="log-out-icon" svgProp={{height: 25, width: 24}} />
          <p className="body2 text-color-body1 ml-2">Log out</p>
        </div>
      </a>
    </>
  );
};
