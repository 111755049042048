import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

// RTK queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useUpdateAdminPreApprovalRequestMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {
  DATE_FORMAT_OPTION,
  formattedDate,
  getTomorrowDateString,
  getUTCDateFromString,
} from '@compt/utils/date-helpers';

// Types
import {DateString} from '@compt/types/common/date-string';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {
  LearningRequestStatus,
  PayloadLearningRequestStatus,
} from '@compt/types/learning-development/learning-request-status';
import {
  getCustomFieldURLKey,
  PreApprovalRequest,
} from '@compt/types/learning-development/pre-approval-request';
import {EligibilityBreakdownOption} from '@compt/types/learning-development/eligibility-breakdown.types';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptReceiptDisplayCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-display-carousel';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {RejectPreApprovalRequestModal} from '@compt/pages/admin-pages/admin-review-lnd/components/reject-pre-approval-request-modal';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';
import {ReviewLndComputedSection} from './review-lnd-computed-section';

interface ReviewPreApprovalFieldValues {
  employee_name: string;
  vendor: string;
  amount_field: number;
  expected_completion_date: DateString;
  receipt_key: string;
  description_reason: string;
  rejection_reason: string;
}

interface ReviewPreApprovalRequestFormProps {
  request: PreApprovalRequest | undefined;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  onPreviousClick: () => void;
  onNextClick: () => void;
  previousDisabled: boolean;
  nextDisabled: boolean;
  programCurrency: string;
}

export const ReviewPreApprovalRequestForm = (props: ReviewPreApprovalRequestFormProps) => {
  const {request, editMode} = props;
  const [, setSearchParams] = useSearchParams();
  const [openRejectionModal, setOpenRejectionModal] = useState(false);

  const session = useGetSessionQuery();

  const [updatePreApprovalRequest, {isLoading: isUpdating}] =
    useUpdateAdminPreApprovalRequestMutation();

  const isReadOnly = !editMode;
  const hasEditableStatus =
    request?.status !== LearningRequestStatus.APPROVED &&
    request?.status !== LearningRequestStatus.REJECTED;

  const formMethods = useForm<ReviewPreApprovalFieldValues>();
  const watchedDescriptionReason = formMethods.watch('description_reason');

  useEffect(() => {
    if (!request) return;

    formMethods.reset({
      employee_name: request.user.full_name,
      vendor: request.request_data['Vendor name'],
      amount_field: request.request_data.Amount,
      receipt_key: request.request_data['Upload document(s)'],
      expected_completion_date: request.request_data['Expected completion date'],
      description_reason: request.request_data['Description and reason'],
    });
  }, [request]);

  function onClickApprove() {
    if (!request) {
      triggerCustomToast(
        'error',
        'There was a problem approving this request',
        'Please try again.',
      );
      return;
    }

    const submission = {
      ...request,
      status: PayloadLearningRequestStatus.APPROVED,
    };

    updatePreApprovalRequest(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem approving this request',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully approved this request');
    });
  }

  async function onClickReject(
    request: PreApprovalRequest,
    status: PayloadLearningRequestStatus,
    rejection_reason: string,
  ) {
    if (!request) {
      triggerCustomToast(
        'error',
        'There was a problem rejecting this request',
        'Please try again.',
      );
      return;
    }

    const submission = {
      ...request,
      status,
      rejection_reason: rejection_reason,
    };

    updatePreApprovalRequest(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem rejecting this request',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully rejected this request');
      setSearchParams({});
    });
  }

  function onSubmit(form: ReviewPreApprovalFieldValues) {
    if (!request) {
      triggerCustomToast('error', 'There was a problem updating this request', 'Please try again.');
      return;
    }

    const submission = {
      ...request,
      request_data: {
        'Vendor name': form.vendor,
        Amount: form.amount_field,
        'Upload document(s)': form.receipt_key,
        'Expected completion date': form.expected_completion_date,
        'Description and reason': form.description_reason,
      },
    };

    updatePreApprovalRequest(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating this request',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully updated this request');
      props.setEditMode(false);
    });
  }

  const receiptUrl: string = (
    request?.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
  ).toString();
  return (
    <>
      <RejectPreApprovalRequestModal
        isOpen={openRejectionModal}
        setIsOpen={setOpenRejectionModal}
        requestToReject={request}
        handleRequestStatusUpdate={onClickReject}
        isUpdating={isUpdating}
      />
      <ComptSidePanel.Content>
        <div className="h-full flex flex-col justify-between">
          <form className="grow" onSubmit={formMethods.handleSubmit(onSubmit)}>
            <fieldset className="flex h-full grow">
              <div className="flex flex-col w-full md:flex-row">
                <div className="flex justify-center bg-surface-tint sm:overflow-y-clip">
                  <div className="flex flex-col w-full sm:w-[480px] items-end p-6">
                    {request && request.request_data['Upload document(s)'] && !props.editMode && (
                      <ComptReceiptDisplayCarousel
                        key={`document-display-for-${request.id}`}
                        documents={[receiptUrl]}
                      />
                    )}
                    {props.editMode && (
                      <ComptReceiptFormCarousel
                        formMethods={formMethods}
                        receiptLabel="Uploaded attachments"
                        userId={session.data?.user_id}
                        initialReceiptValue={receiptUrl}
                        receiptDomain="learning_and_dev"
                        supportingDocDomain="learning_and_dev_supporting_document"
                        receiptTestDataId="uploaded-document-key-field"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full grow-[2] p-6">
                {request?.rejection_reason && (
                  <ComptRejectionReason rejectionReason={request.rejection_reason} />
                )}
                <p className="body2 mb-1">Requested on</p>
                <p className="body1 mb-400">
                  {formattedDate(request?.requested_on, DATE_FORMAT_OPTION['month dd yyyy'])}
                </p>
                <p className="body2 mb-1">Program</p>
                <p className="body1 mb-400">{request?.program.name}</p>
                {/* TODO: refactor to account for custom fields in COMPT-5731 */}
                <ComptTextField
                  name="employee_name"
                  label="Employee name"
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly
                  disabled
                />
                <ComptTextField
                  name="vendor"
                  label="Vendor"
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly={isReadOnly}
                  disabled={isReadOnly}
                  validation={{required: 'Vendor is required'}}
                  errors={formMethods.formState.errors.vendor}
                />
                <ComptCurrencyField
                  name="amount_field"
                  label="Amount covered"
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly={isReadOnly}
                  disabled={isReadOnly}
                  validation={{
                    required: 'Amount is required',
                    min: 0.01,
                  }}
                  placeholder="0.00"
                  errors={formMethods.formState.errors.amount_field}
                  givenCurrency={props.programCurrency}
                />
                {request &&
                request?.program.eligibility !== EligibilityBreakdownOption.CASE_BY_CASE ? (
                  <ReviewLndComputedSection request={request} />
                ) : null}
                {/* TODO: display User entered, Available budget, over budget in COMPT-5728 */}
                <ComptDatePickerField
                  name="expected_completion_date"
                  label="Expected completion date"
                  control={formMethods.control}
                  register={formMethods.register}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                  errors={formMethods.formState.errors.expected_completion_date}
                  validation={{required: 'Expected completion date is required'}}
                  startDate={getUTCDateFromString(getTomorrowDateString()) || null}
                  allowFutureDates
                />
                <ComptTextAreaField
                  name="description_reason"
                  label="Description and reason"
                  control={formMethods.control}
                  register={formMethods.register}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                  validation={{required: 'Description and reason is required'}}
                  watchedValue={watchedDescriptionReason}
                  maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                  errors={formMethods.formState.errors.description_reason}
                />
              </div>
            </fieldset>
          </form>
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="w-full bg-white">
          {!props.editMode && (
            <div className="flex justify-between w-full">
              <div className="flex gap-x-2">
                {hasEditableStatus && (
                  <>
                    <ComptButton
                      buttonType={ComptButtonType.PRIMARY}
                      onClick={onClickApprove}
                      disabled={isUpdating}
                    >
                      Approve
                    </ComptButton>
                    <ComptButton
                      buttonType={ComptButtonType.SECONDARY}
                      onClick={() => props.setEditMode(true)}
                      disabled={isUpdating}
                    >
                      Edit
                    </ComptButton>
                    <ComptButton
                      buttonType={ComptButtonType.DESTRUCTIVE}
                      onClick={() => setOpenRejectionModal(true)}
                      disabled={isUpdating}
                    >
                      Reject
                    </ComptButton>
                  </>
                )}
                {!hasEditableStatus && (
                  <ComptButton
                    buttonType={ComptButtonType.SECONDARY}
                    onClick={() => {
                      setSearchParams({});
                    }}
                  >
                    Close
                  </ComptButton>
                )}
              </div>
              <div className="flex">
                <ComptButton
                  textClassName={`text-color-link ${
                    props.previousDisabled && 'text-color-body1 cursor-not-allowed'
                  }`}
                  buttonType={ComptButtonType.BORDERLESS}
                  disabled={props.previousDisabled}
                  onClick={props.onPreviousClick}
                  className="text-color"
                >
                  Previous request
                </ComptButton>
                <ComptButton
                  textClassName={`text-color-link ${
                    props.nextDisabled && 'text-color-body1 cursor-not-allowed'
                  }`}
                  disabled={props.nextDisabled}
                  buttonType={ComptButtonType.BORDERLESS}
                  onClick={props.onNextClick}
                >
                  Next request
                </ComptButton>
              </div>
            </div>
          )}
          {props.editMode && (
            <div className="flex gap-x-2">
              <ComptButton type="submit" onClick={formMethods.handleSubmit(onSubmit)}>
                Save
              </ComptButton>
              <ComptButton
                buttonType={ComptButtonType.SECONDARY}
                onClick={() => {
                  formMethods.reset();
                  props.setEditMode(false);
                }}
              >
                Cancel
              </ComptButton>
            </div>
          )}
        </div>
      </ComptSidePanel.Footer>
    </>
  );
};
