import React from 'react';
import {twMerge} from 'tailwind-merge';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';

interface ProgramFormFooterProps {
  className?: string;
  isUpdating: boolean;
  readOnly: boolean;
  onCloseCancel: () => void;
  onPreviousClick?: () => void;
  onSaveDraftClick: () => void;
  onNextClick: {(): void} | null;
  alternateNextButtonText?: string;
}
export const ProgramFormFooter = (props: ProgramFormFooterProps) => (
  <div
    className={twMerge(`border-t w-full justify-between px-600 pb-600 pt-200 ${props.className}`)}
  >
    <ComptButton
      size={ComptButtonSize.LARGE}
      buttonType={ComptButtonType.BORDERLESS}
      onClick={props.onCloseCancel}
      disabled={props.isUpdating}
    >
      {props.readOnly ? 'Close' : 'Cancel'}
    </ComptButton>
    <div className="flex gap-x-6">
      {props.onPreviousClick && (
        <ComptButton
          onClick={props.onPreviousClick}
          size={ComptButtonSize.LARGE}
          buttonType={ComptButtonType.BORDERLESS}
        >
          Previous
        </ComptButton>
      )}
      {!props.readOnly && (
        <ComptButton
          onClick={props.onSaveDraftClick}
          size={ComptButtonSize.LARGE}
          buttonType={ComptButtonType.SECONDARY}
          disabled={props.isUpdating}
        >
          Save draft
        </ComptButton>
      )}
      {props.onNextClick && (
        <ComptButton
          onClick={props.onNextClick}
          size={ComptButtonSize.LARGE}
          buttonType={ComptButtonType.PRIMARY}
          disabled={props.isUpdating}
        >
          {props.alternateNextButtonText || 'Next'}
        </ComptButton>
      )}
    </div>
  </div>
);
