import React from 'react';

// Types
import {ComptDropDownProps} from '../forms/compt-dropdown/compt-dropdown';
import {GroupTableFieldValues} from './compt-group-table.types';

// Components
import {Control, Controller} from 'react-hook-form';
import {Listbox} from '@headlessui/react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Styles
import comptColors from '@compt/styles/compt-colors';

interface GroupTableDropdownProps<TOptionType, TName = string>
  extends ComptDropDownProps<TOptionType, TName> {
  control: Control<GroupTableFieldValues>;
  optionClassName?: string;
  textClassName?: string;
  showDeleteOnHover?: boolean;
  onDelete?: () => void;
  errors?: {message?: string};
  showDuplicationError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDropdownChange?: (onFieldChange: (e: any[]) => void, e: any) => void;
}

export const GroupTableDropdown = <TOptionType, TName = string>(
  props: GroupTableDropdownProps<TOptionType, TName>,
) => {
  const comparator = (a: TOptionType, b: TOptionType) => {
    if (typeof props.by === 'function') {
      return props.by(a, b);
    }

    if (props.by && a && b) {
      return a[props.by] === b[props.by];
    }

    return a === b;
  };

  const DisplayText = (value: TOptionType): string => {
    const displayText = props.getDisplayText(value);

    return displayText ?? (props.placeholder || '');
  };

  return (
    <Controller
      control={props.control}
      defaultValue={null}
      name={props.name as string}
      rules={{required: 'Please select an option'}}
      render={({field: {onChange, value, ref}}) => (
        <div className="flex w-full">
          <Listbox
            value={value}
            onChange={(e) => {
              if (props.onDropdownChange) {
                props.onDropdownChange(onChange, e);
                return;
              }
              onChange(e);
            }}
            disabled={props.disabled}
            by={comparator}
          >
            <Listbox.Button
              placeholder={props.placeholder}
              data-testid={props['data-testid']}
              ref={ref}
              dir="ltr"
              className={`
                flex justify-between overflow-hidden items-center
                cursor-pointer rounded-lg min-w-full relative group
                bg-surface-background px-400 py-300 text-left border-r`}
              //  Needed since tailwind bg utility classes aren't applying
              style={{
                backgroundColor: props.disabled ? comptColors.gray['300'] : comptColors.gray['050'],
                borderRadius: 'inherit',
              }}
            >
              <div className="flex justify-between w-full">
                <span className={props.textClassName}>
                  {DisplayText(value)}
                  {(props.showDuplicationError || props.errors) && (
                    <div className="flex items-center mt-1">
                      <ComptSvgIcon
                        iconName="alert-hexagonal-icon"
                        id={props.id}
                        data-tooltip-content="Field is required"
                        className="mr-2"
                        svgProp={{width: '14px', height: '14px'}}
                      />
                      <p className="text-color-error">
                        {props.showDuplicationError
                          ? 'Please select non-overlapping groups'
                          : props.errors?.message}
                      </p>
                    </div>
                  )}
                </span>
                <div className="flex">
                  {props.showDeleteOnHover && (
                    <button
                      className="hidden group-hover:block"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (props.onDelete) props.onDelete();
                      }}
                    >
                      <ComptSvgIcon
                        iconName="trash-icon"
                        svgProp={{width: '20px', height: '20px'}}
                      />
                    </button>
                  )}
                  {!props.disabled && (
                    <ComptSvgIcon
                      iconName="chevron-down-icon"
                      svgProp={{width: '24px', height: '24px'}}
                    />
                  )}
                </div>
              </div>
            </Listbox.Button>
            <Listbox.Options
              className={`absolute mt-16 z-10 max-h-60 rounded-md bg-surface-background py-1 min-w-max
            text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
            overflow-auto
            `}
              // Positions dropdown options properly, comments needed to disabled Typescript error
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: Unreachable code error
              anchor={'bottom start'}
            >
              {props.options &&
                props.options.map((option) => (
                  <Listbox.Option
                    key={props.getKey(option)}
                    className={({active}) =>
                      twMerge(`
                        relative cursor-default select-none py-2 pl-3 pr-9 ${props.optionClassName}
                        ${active ? ' bg-gray-200 cursor-pointer' : ''}
                        `)
                    }
                    value={option}
                    data-testid={`listbox-option-${props.getDisplayText(option)}`}
                  >
                    <div className="flex flex-row options-baseline">
                      <span
                        className={twMerge(
                          `body3 block truncate compt-dropdown-option ${props.optionClassName}`,
                        )}
                      >
                        {props.getDisplayText(option)}
                      </span>
                      {props.getSecondaryText && (
                        <span className="body3 truncate text-color-tertiary pl-2">
                          {props.getSecondaryText(option)}
                        </span>
                      )}
                    </div>
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Listbox>
        </div>
      )}
    />
  );
};
