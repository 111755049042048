import {CustomDescriptionPerkCategory, PerkCategory} from '@compt/types/perk-category';
import {comptApiSlice, ComptRTKTags} from './api-slice';
import {AccountAllotments, Allotment} from '@compt/types/allotments';

interface TransformAccountAllotmentsResponse {
  allotments: (Allotment & {balance: string})[];
  team_bonus_allotments?: (Allotment & {balance: string})[];
  perk_category_description_customizations: CustomDescriptionPerkCategory[];
}

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllotments: builder.query<AccountAllotments, number>({
      providesTags: [ComptRTKTags.STIPEND_ALLOTMENTS],
      query: (userId) => `users/${userId}/account/`,
      transformResponse: (response: unknown, meta, arg) =>
        transformAccountAllotments(response as TransformAccountAllotmentsResponse),
    }),
  }),
});

export const {useGetAllotmentsQuery} = extendedComptApiSlice;

export const getCustomDescriptionPerkCategories = (
  categories: PerkCategory[],
  customDescriptionPerkCategories: CustomDescriptionPerkCategory[],
) => {
  if (categories) {
    return categories.map((perkCategory) => {
      const customCategory = customDescriptionPerkCategories?.find(
        (category) => category.perk_category === perkCategory.id,
      );
      if (customCategory) {
        perkCategory.description = customCategory.description;
      }
      return perkCategory;
    });
  }
  return [];
};

const transformAccountAllotments = (
  response: TransformAccountAllotmentsResponse,
): AccountAllotments => {
  const transformedAllotments = response.allotments.map((allotment) => ({
    ...allotment,
    perk_categories: getCustomDescriptionPerkCategories(
      allotment.perk_categories,
      response.perk_category_description_customizations,
    ),
    balance_amount: parseFloat(allotment.balance), // Ensure `balance` is converted to a float
  }));

  const transformedTeamRecognitionAllotments = response.team_bonus_allotments?.map((allotment) => ({
    ...allotment,
    balance_amount: parseFloat(allotment.balance),
  }));

  return {
    ...response,
    allotments: transformedAllotments,
    team_bonus_allotments: transformedTeamRecognitionAllotments,
  };
};
