import React, {Dispatch, SetStateAction} from 'react';
import {Draft, produce} from 'immer';
import {TableColumn} from 'react-data-table-component';
import {
  SelectionChangeValues,
  FilterConfigOption,
  ChangeValueTypes,
  FilterValues,
  FilterValueTypes,
  FilterConfigItem,
  SelectionOptionValues,
  FilterConfigType,
} from '../compt-filter-bar/compt-filter-bar.types';
import {ComptTableProps, ComptTableColumn, ColumnDefinition} from './compt-table.types';

export class ComptTableController {
  static getTotalPages = (totalCount: number, itemsPerPage: number) =>
    Math.ceil(totalCount / itemsPerPage);

  static getColumnsList = (columnDefinition: ColumnDefinition<unknown>) => {
    const columnsList = Object.keys(columnDefinition)
      .map((key) => columnDefinition[key])
      .sort((a, b) => a.order - b.order);

    return columnsList;
  };

  static getVisibleColumns = (columnsList: ComptTableColumn<unknown>[]) =>
    columnsList
      ?.filter((column: ComptTableColumn<unknown>) => !column.omit)
      .reduce((previousValue: {[key: string]: boolean}, column: ComptTableColumn<unknown>) => {
        previousValue[column.id || ''] = true;
        return previousValue;
      }, {});

  static _filterChanged = (
    {
      filterKey,
      changeValue,
    }: {
      filterKey: keyof FilterValues;
      changeValue: ChangeValueTypes;
    },
    filterConfiguration: FilterConfigType | undefined,
    setCurrentFilterValues: Dispatch<SetStateAction<Record<string, FilterValueTypes> | undefined>>,
  ) => {
    const valuesChangeHandler = filterConfiguration?.[filterKey]?.valuesChangeHandler;
    const changedFilterValues = ComptTableController.generateValueChangeDraft(
      {filterKey, changeValue},
      valuesChangeHandler,
    );

    setCurrentFilterValues(changedFilterValues);
  };

  /**
   * Generates a draft of the filter values being changed.
   * Depending on the type of filter, will alter each draft[filterKey] to be an array of strings,
   * Selection Options, or Range Value Option
   */
  static generateValueChangeDraft(
    {
      filterKey,
      changeValue,
    }: {
      filterKey: keyof FilterValues;
      changeValue: ChangeValueTypes;
    },
    valuesChangeHandler?: FilterConfigItem['valuesChangeHandler'],
  ): Draft<FilterValues> {
    const changedValues = produce((draft: Draft<FilterValues>) => {
      if (!draft?.[filterKey]) {
        console.error(
          `Draft is either null or key '${filterKey}' does not exist.
          Define ${filterKey} in your filter configurations and initial filter values of the ComptTable`,
        );
        return;
      }

      if (filterKey === 'textSearch' && typeof changeValue === 'string') {
        draft[filterKey] = [changeValue];
        return draft;
      }

      if (!valuesChangeHandler) {
        console.error(
          'A value change handler does not exist for a data structure that requires it.',
        );
        return;
      }

      const valueChangeDraft = valuesChangeHandler(draft[filterKey], changeValue);

      if (!valueChangeDraft) {
        console.error('Value change handler did not return a valid draft.');
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      draft[filterKey] = valueChangeDraft as any;

      return draft;
    });

    return changedValues as Draft<FilterValues>;
  }

  /**
   * Generates column options for the Column Select Filter
   */
  static getColumnOptions: (
    columnsList: ComptTableColumn<unknown>[],
  ) => FilterConfigOption[] | undefined = (columnsList: ComptTableColumn<unknown>[]) => {
    const columnsToOrder = columnsList?.filter(
      (column: ComptTableColumn<unknown>) => !column.disableRemoval,
    );

    const orderedFilters: FilterConfigOption[] | undefined = columnsToOrder?.map(
      (column: ComptTableColumn<unknown>): FilterConfigOption => {
        const filterOption: {[key: string]: string | number} = {};
        if (column.id) {
          filterOption.id = column.id;
        }
        if (column.name) {
          filterOption.name = column.name.toString();
        }
        return filterOption as FilterConfigOption;
      },
    );

    return orderedFilters;
  };

  static setColumnUI = (
    columnsList?: ComptTableColumn<unknown>[],
    visibleColumns?: {[key: string]: boolean},
  ) => {
    if (!columnsList) return [];

    return columnsList.map((column: ComptTableColumn<unknown>) => ({
      ...column,
      omit: column.id && !visibleColumns?.[column.id],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selector: (row: any) => {
        const value = column.selector?.(row);
        const formattedValue = typeof value === 'bigint' ? value.toString() : value;
        if (formattedValue || formattedValue === 0) {
          if (column.enableToolTip) {
            return (
              <div style={{height: '72px', display: 'flex', alignItems: 'center'}}>
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title={column.getAllText ? column.getAllText(row) : value?.toString()}
                >
                  {formattedValue}
                </div>
              </div>
            );
          }
          return (
            <div
              style={{
                height: '72px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {formattedValue}
            </div>
          );
        }

        return '-';
      },
    }));
  };

  /**
   * Filters column based on user's visibleColumns, and formats UI to be displayed
   */
  static getFilteredColumnList = (
    columnsList: ComptTableColumn<unknown>[],
    visibleColumns: {[key: string]: boolean},
  ): TableColumn<unknown>[] => {
    if (!columnsList) return [];

    const uiFormattedColumns = this.setColumnUI(columnsList, visibleColumns);

    return uiFormattedColumns.map((column) => {
      const {enableToolTip, getAllText, ...rest} = column;
      return rest as TableColumn<unknown>;
    });
  };

  /**
   * Generates list of column options that should be active based on user's visibleColumns
   */
  static getSelectedColumnOptions = (
    columnDefinition: ColumnDefinition<unknown>,
    visibleColumns?: {[key: string]: unknown},
  ): SelectionOptionValues[] => {
    if (!visibleColumns) return [] as SelectionOptionValues[];

    return Object.keys(visibleColumns).map((key) => ({
      id: key,
      name: columnDefinition[key].name ?? '',
    }));
  };

  /**
   * Handles changes made when user selects columns to show/hide within the Column Select Filter
   */
  static handleColumnsChange = (
    change: SelectionChangeValues,
    visibleColumns: {[key: string]: boolean},
    setVisibleColumns: Dispatch<SetStateAction<{[key: string]: boolean}>>,
  ) => {
    const visibleColumnDraft = produce(visibleColumns, (draft) => {
      if (change.checked && change.selection.id) {
        draft[change.selection.id] = change.checked;
      } else {
        change.selection.id && delete draft[change.selection.id];
      }
    });

    setVisibleColumns(visibleColumnDraft);
  };

  static handlePageChange = async (
    page: number,
    props: ComptTableProps<unknown>,
    setCurrentPage: Dispatch<SetStateAction<number>>,
    currentFilterValues?: FilterValues,
  ) => {
    setCurrentPage(page);
    props.itemsPerPage &&
      currentFilterValues &&
      props.onChangeQueryValues?.(currentFilterValues, {page, limit: props.itemsPerPage});
  };
}
