import React, {useRef} from 'react';

// Types
import {dummyCourseReviews} from '../dummy-reviews';

// Components
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {ComptCommunityCard} from '@compt/common/compt-community-card/compt-community-card';

export const CommunityActivityFeed = () => {
  const loadMoreCommunityFeedRef = useRef<HTMLDivElement>(null);
  const isLoading = false;
  const isFetching = false;

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col max-h-max mb-24">
          <div>
            <>
              {(isLoading || isFetching || dummyCourseReviews.length > 0) && (
                <div className="flex items-center justify-between mb-6">
                  <p className="heading1">Activity feed</p>
                  <div className=" w-[320px]">
                    {' '}
                    <SearchFilter
                      label="community feed search filter"
                      handleChange={() => {}}
                      currentValues={[]}
                      options={[
                        {
                          id: 1,
                          name: 'Community Feed Search',
                          placeholder: 'Search keywords',
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
              {dummyCourseReviews && dummyCourseReviews.length > 0
                ? dummyCourseReviews.map((review, i) => (
                    <div className="mb-400" key={`${review.id}-${i}`}>
                      <ComptCommunityCard review={review} />
                    </div>
                  ))
                : null}
            </>
            <div ref={loadMoreCommunityFeedRef}>
              {(isLoading || isFetching) && (
                <div className="flex flex-col w-full m-6">
                  <div className="mb-[9px]">
                    <ComptLoadingAnimation />
                  </div>
                  <p className="label3 text-color-body1 -mt-7 mx-auto">Loading more reviews...</p>
                </div>
              )}
            </div>

            {!(isLoading || isFetching) && !dummyCourseReviews?.length && (
              <div className="flex flex-col items-center">
                <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
                <h4 className="text-center">No course reviews yet.</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
