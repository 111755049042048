import React, {useEffect} from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';

// Types
import {ReviewFieldValues} from './review-field-values.types';

// Components
import {ReviewForm} from './review-form';

export const ReviewFormTab = () => {
  const formMethods = useForm<ReviewFieldValues>();

  useEffect(() => {
    // TODO: replace values with existing data in COMPT-6069
    formMethods.reset({
      describe_purchased: 'AI for Dummies 101',
      rating_number: 5,
      rating_detail: 'Found this course to be extremely helpful in understanding AI.',
      keywords: ['keyword'],
    });
  }, []);

  return (
    <div className="p-6">
      <ReviewForm formMethods={formMethods} readOnly={true} />
    </div>
  );
};
