import React, {HTMLAttributes} from 'react';
import {
  SupportedCountriesType,
  formatCurrencyFromCountryCode,
  parseCurrency,
} from '@compt/utils/international-helpers';
import {ComptProgressBar} from '@compt/common/compt-progress-bar/compt-progress-bar';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

export interface ComptSpentCardProps extends HTMLAttributes<HTMLElement> {
  amountTotal: number | string;
  amountSpent: number | string;
  currency: string;
  userCountryCode?: SupportedCountriesType;
  isForLearning?: boolean;
  reversed?: boolean;
}

export const ComptSpentCard = (props: ComptSpentCardProps) => {
  const isMobileView = useIsMobileView();

  const amountTotal: number = parseCurrency(props.amountTotal) || 0;
  const amountSpent: number = parseCurrency(props.amountSpent) || 0;
  const amountToSpend = amountTotal - amountSpent;

  const percentageAmount = props.reversed
    ? 100 - (amountSpent / amountTotal) * 100
    : (amountSpent / amountTotal) * 100;

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row mb-400 sm:mb-000 sm:mt-000 sm:pb-400">
        {isMobileView ? (
          <>
            <p className="heading1 text-color-body1">
              {formatCurrencyFromCountryCode(amountToSpend, props.userCountryCode, props.currency)}
              &nbsp;
            </p>
            <p className="heading3 text-color-body1">
              {props.isForLearning ? 'to request' : 'to spend'}
            </p>
          </>
        ) : (
          <>
            <p className="heading1 text-color-body1">
              {formatCurrencyFromCountryCode(amountToSpend, props.userCountryCode, props.currency)}
              &nbsp;
            </p>
            <p className="heading3 text-color-body1">
              {' '}
              {props.isForLearning ? 'to request' : 'to spend'}
            </p>
          </>
        )}
      </div>
      <ComptProgressBar className="pb-4" percentage={percentageAmount} />
      <div className="flex sm:justify-end">
        <p className="label1 text-color-body1">
          {formatCurrencyFromCountryCode(props.amountSpent, props.userCountryCode, props.currency)}
          &nbsp;{props.isForLearning ? 'requested' : 'spent'}&nbsp;/&nbsp;
          {formatCurrencyFromCountryCode(amountTotal, props.userCountryCode, props.currency)}
        </p>
      </div>
    </div>
  );
};
