import React from 'react';

// Hooks and methods
import {isExpiredDate, timeAgo} from '@compt/utils/date-helpers';

// Types
import {LearningStatusFormats} from '@compt/pages/admin-pages/admin-review-lnd/review-lnd.types';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {EXPENSE_STATUS_INFO, ExpenseStatus} from '@compt/types/stipend-expense';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptInitialsAvatar} from '@compt/common/compt-initials-avatar/compt-initials-avatar';
import {ComptStyleType} from '@compt/utils/style-helpers';

export const ProgramActivity = ({preApprovalRequest}: {preApprovalRequest: PreApprovalRequest}) => {
  const requestApprover =
    preApprovalRequest?.secondary_approver?.full_name ??
    preApprovalRequest?.primary_approver?.full_name;

  const requestApproverFirstName =
    preApprovalRequest?.secondary_approver?.first_name ??
    preApprovalRequest?.primary_approver?.first_name;
  const requestApproverLastName =
    preApprovalRequest?.secondary_approver?.last_name ??
    preApprovalRequest?.primary_approver?.last_name;

  const preApprovalStatusFormat = LearningStatusFormats[preApprovalRequest.status];

  const {isExpired} = isExpiredDate(preApprovalRequest.allotment?.expiration_date);

  const ProgramActivityItem = ({
    activityUserFullname,
    activityUserFirstname,
    activityUserLastname,
    systemActivity = false,
    activityTimestamp,
    activityText,
    isLastItem = false,
  }: {
    activityUserFullname?: string;
    activityUserFirstname?: string;
    activityUserLastname?: string;
    systemActivity?: boolean;
    activityTimestamp: string;
    activityText: React.ReactNode;
    isLastItem?: boolean;
  }) => (
    <>
      <div className="flex">
        {activityUserFirstname && activityUserLastname && (
          <ComptInitialsAvatar className="h-12 w-12 mr-3 border-[2px]">
            <p className="heading3 text-brand-700">
              {activityUserFirstname[0]}
              {activityUserLastname[0]}
            </p>
          </ComptInitialsAvatar>
        )}
        {systemActivity && (
          <ComptInitialsAvatar className="h-12 w-12 mr-3 border-[2px]">
            <p className="heading3 text-brand-700">S</p>
          </ComptInitialsAvatar>
        )}
        <div>
          <div className="flex column gap-x-2">
            <p className="label4 text-gray-800">{activityUserFullname}</p>
            <p className="body4 text-gray-600">{timeAgo(activityTimestamp)}</p>
          </div>
          <p className="body3 text-gray-700">{activityText}</p>
        </div>
      </div>
      {!isLastItem && <hr className="my-6 border-[#EAECF0]" />}
    </>
  );

  return (
    <div>
      <div className="flex flex-row gap-x-2 items-center mb-6">
        <ComptSvgIcon iconName="program-activity-icon" />
        <h4>Program activity</h4>
      </div>
      {isExpired &&
        (preApprovalRequest.request_status === LearningRequestStatus.PRE_APPROVAL_REQUESTED ||
          preApprovalRequest.request_status === LearningRequestStatus.PRE_APPROVED ||
          preApprovalRequest.request_status === LearningRequestStatus.PARTIALLY_APPROVED) && (
          <ProgramActivityItem
            activityTimestamp={preApprovalRequest.allotment?.expiration_date || ''}
            systemActivity
            activityUserFullname="System notice"
            activityText={
              <>
                Request status updated to{' '}
                <ComptPill pillType={ComptStyleType.DESTRUCTIVE} className="ml-1">
                  Expired
                </ComptPill>
              </>
            }
          />
        )}
      {preApprovalRequest.expense && (
        <>
          {(preApprovalRequest?.request_status === LearningRequestStatus.REIMBURSED ||
            preApprovalRequest.expense.status === ExpenseStatus.Processed) && (
            <ProgramActivityItem
              activityTimestamp={preApprovalRequest.expense.closed_on}
              systemActivity
              activityUserFullname="System notice"
              activityText={
                <>
                  Request status updated to{' '}
                  <ComptPill pillType={ComptStyleType.SUCCESS} className="ml-1">
                    Reimbursed
                  </ComptPill>
                </>
              }
            />
          )}
          {(preApprovalRequest?.request_status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
            preApprovalRequest.expense.status === ExpenseStatus.Rejected) && (
            <ProgramActivityItem
              activityUserFullname={
                preApprovalRequest?.reimbursement_request?.reviewed_by?.full_name || ''
              }
              activityUserFirstname={
                preApprovalRequest.reimbursement_request?.reviewed_by?.first_name || ''
              }
              activityUserLastname={
                preApprovalRequest.reimbursement_request?.reviewed_by?.last_name || ''
              }
              activityTimestamp={preApprovalRequest.expense.closed_on}
              activityText={
                <>
                  Reimbursement request{' '}
                  <ComptPill
                    pillType={EXPENSE_STATUS_INFO[preApprovalRequest.expense?.status].statusStyle}
                    className="ml-1"
                  >
                    Rejected
                  </ComptPill>
                </>
              }
            />
          )}
          <ProgramActivityItem
            activityUserFullname={preApprovalRequest.user.full_name}
            activityTimestamp={preApprovalRequest.expense.created_on}
            activityUserFirstname={preApprovalRequest.user.first_name}
            activityUserLastname={preApprovalRequest.user.last_name}
            activityText="Submitted reimbursement request"
          />
        </>
      )}
      {preApprovalRequest.status === LearningRequestStatus.APPROVED && (
        // TO-DO: Update after the bug with primary vs secondary approvers is fixed in COMPT-5975
        //   ((!preApprovalRequest.secondary_approver &&
        //     preApprovalRequest.approved_by_primary_approver_on) ||
        //     (preApprovalRequest.approved_by_primary_approver_on &&
        //       preApprovalRequest.approved_by_secondary_approver_on))
        <ProgramActivityItem
          activityUserFullname={requestApprover || ''}
          activityUserFirstname={requestApproverFirstName}
          activityUserLastname={requestApproverLastName}
          activityTimestamp={
            preApprovalRequest.approved_by_secondary_approver_on ||
            preApprovalRequest.approved_by_primary_approver_on ||
            ''
          }
          activityText={
            <>
              Request status updated to
              <ComptPill pillType={preApprovalStatusFormat?.statusStyle} className="ml-1">
                {preApprovalStatusFormat?.label}
              </ComptPill>
            </>
          }
        />
      )}
      {preApprovalRequest.request_status === LearningRequestStatus.PRE_APPROVAL_REJECTED &&
        preApprovalRequest.rejected_by && (
          <ProgramActivityItem
            activityUserFullname={preApprovalRequest.rejected_by.full_name}
            activityUserFirstname={preApprovalRequest.rejected_by.first_name}
            activityUserLastname={preApprovalRequest.rejected_by.last_name}
            activityTimestamp={preApprovalRequest.reviewed_on || ''}
            activityText={
              <>
                Request status updated to{' '}
                <ComptPill pillType={preApprovalStatusFormat?.statusStyle} className="ml-1">
                  {preApprovalStatusFormat?.label}
                </ComptPill>
              </>
            }
          />
        )}
      <ProgramActivityItem
        activityUserFullname={preApprovalRequest.user.full_name}
        activityUserFirstname={preApprovalRequest.user.first_name}
        activityUserLastname={preApprovalRequest.user.last_name}
        activityTimestamp={preApprovalRequest.requested_on}
        activityText="Submitted request"
        isLastItem={true}
      />
      <hr className="my-6 border-gray-300" />
    </div>
  );
};
