import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {Tooltip} from 'react-tooltip';

type ComptHelpScoutLinkProps = {
  sidebarOpen?: boolean;
};

export const ComptHelpScoutLink = (props: ComptHelpScoutLinkProps) => {
  const {sidebarOpen} = props;
  const isMobile = useIsMobileView();

  return (
    <>
      {!sidebarOpen && (
        <Tooltip
          anchorSelect="#collapse-helpscout-button"
          place="right"
          opacity={100}
          style={{borderRadius: '6px'}}
        >
          Help
        </Tooltip>
      )}
      <button
        id="collapse-helpscout-button"
        onClick={() => window.Beacon('open')}
        // In-line style necessary to override admin page stylesheet
        style={{borderRadius: '6px', padding: '8px 12px'}}
      >
        <div className="flex">
          <ComptSvgIcon iconName="help-circle-gray" svgProp={{height: 25, width: 24}} />
          {(isMobile || sidebarOpen) && (
            <p className={`body2 text-color-body1 ${isMobile ? 'pl-2' : 'pl-3'}`}>Help</p>
          )}
        </div>
      </button>
    </>
  );
};
