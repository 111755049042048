import React, {useCallback, useEffect, useState} from 'react';

// RTK queries
import {
  useCreateCommentMutation,
  useGetChannelCommentsQuery,
} from '@compt/app/services/api/lnd-comments-slice';

// Types
import {Comment} from '@compt/types/comments/comment';

// Components
import {
  CommentFieldValues,
  ComptCommentBox,
} from '@compt/common/compt-comment-box/compt-comment-box';

interface CommentsTabProps {
  channelId: number;
}

export const ComptCommentsTab = (props: CommentsTabProps) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [offset, setOffset] = useState(0);

  const {data, isLoading, isFetching, refetch} = useGetChannelCommentsQuery(
    {channelId: props.channelId, params: {offset}},
    {skip: !props.channelId},
  );

  const [createComment] = useCreateCommentMutation();

  useEffect(() => {
    if (data?.results) {
      setComments((prevComments) => {
        const allComments = [
          ...data.results.filter((comment) => !prevComments.some((prev) => prev.id === comment.id)),
          ...prevComments,
        ];
        return allComments.sort((a, b) => b.id - a.id);
      });
    }
  }, [data]);

  const loadMoreComments = useCallback(() => {
    if (data?.next && !isFetching) {
      const url = new URL(data.next);
      const newOffset = parseInt(url.searchParams.get('offset') || '0', 10);
      setOffset(newOffset);
    }
  }, [data]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    // Trigger `loadMoreComments` when scrolled to the top
    if (container.scrollTop <= -Math.abs(container.scrollHeight - container.clientHeight)) {
      loadMoreComments();
    }
  };

  async function submitComment(form: CommentFieldValues) {
    const submission = {
      channelId: props.channelId,
      message: form.comment_input,
    };

    return createComment(submission).then((results) => {
      if ('data' in results) {
        setComments((prevComments) => {
          const updatedComments = [results.data, ...prevComments];
          return updatedComments.sort((a, b) => b.id - a.id);
        });
      }
      return results;
    });
  }

  return (
    <>
      <div className="flex flex-1">
        <ComptCommentBox
          messages={comments}
          onClickSend={submitComment}
          isLoading={isLoading || isFetching}
          onRefreshFeedClicked={() => {
            refetch();
          }}
          onScroll={handleScroll}
        />
      </div>
    </>
  );
};
