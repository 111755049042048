import React, {useEffect} from 'react';

// Hook and methods
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';

// RTK queries
import {useUpdateLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Components
import {ProgramOverviewFields} from './program-overview-fields';
import {ProgramFormFooter} from '../form-footer';
import {LearningDevelopmentStepper} from '../learning-development-stepper';

// Types
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {LnDProgramForms} from '../../learning-development.types';
import {ProgramOverviewFieldValues} from './program-overview.types';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';

interface UpdateProgramOverviewProps {
  existingData: LearningDevelopmentProgram;
  readOnly: boolean;
}

export const UpdateProgramOverview = (props: UpdateProgramOverviewProps) => {
  const {existingData, readOnly} = props;

  const {setStepStatusMap} = useFormStepperContext();

  const navigate = useNavigate();

  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();

  const formMethods = useForm<ProgramOverviewFieldValues>();
  const {dirtyFields} = formMethods.formState;

  useEffect(() => {
    formMethods.reset({
      name: existingData?.name,
      description: existingData?.description,
    });
  }, [existingData]);
  function onOverviewUpdate(
    form: ProgramOverviewFieldValues,
    buttonCallback: (updatedData: LearningDevelopmentProgram) => void,
  ) {
    const updatedSubmission = {
      id: existingData.id,
      name: form.name,
      description: form.description,
      company: {
        id: existingData.company.id,
        name: existingData.company.name,
      },
    };

    updateProgram(updatedSubmission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
        return;
      }

      buttonCallback(results.data);
    });
  }

  function onNextClick() {
    if (props.readOnly) {
      setStepStatusMap((prevState) => ({
        ...prevState,
        [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.READ_ONLY,
        [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
      }));
      return;
    }
    if (Object.keys(dirtyFields).length === 0) {
      setStepStatusMap((prevState) => ({
        ...prevState,
        [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.COMPLETE,
        [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
      }));
      return;
    }

    formMethods.handleSubmit((form) =>
      onOverviewUpdate(form, () => {
        setStepStatusMap((prevState) => ({
          ...prevState,
          [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.COMPLETE,
          [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function onSaveDraftClick() {
    if (Object.keys(dirtyFields).length === 0) {
      if (existingData.is_draft) {
        triggerCustomToast('success', 'Program saved as a draft');
      }
      return;
    }

    formMethods.handleSubmit((form) =>
      onOverviewUpdate(form, (updatedData: LearningDevelopmentProgram) => {
        if (updatedData.is_draft) {
          triggerCustomToast('success', 'Program saved as a draft');
        }
      }),
    )();
  }

  return (
    <div className="flex flex-col h-full pb-900 justify-between">
      <LearningDevelopmentStepper isFormDirty={Object.keys(dirtyFields).length > 0}>
        <ProgramOverviewFields
          formMethods={formMethods}
          readOnly={readOnly}
          existingData={existingData}
        />
      </LearningDevelopmentStepper>
      <ProgramFormFooter
        className="flex flex-shrink-0"
        isUpdating={isUpdating}
        readOnly={readOnly}
        onCloseCancel={() => {
          formMethods.reset();
          navigate('/manage-programs');
        }}
        onSaveDraftClick={onSaveDraftClick}
        onNextClick={onNextClick}
      />
    </div>
  );
};
