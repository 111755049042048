// TO-DO: Delete after COMPT-6070

import {DevelopmentCourseReview} from '@compt/types/community/common-types';
import {PerkCategory} from '@compt/types/perk-category';

const perkCategories: PerkCategory[] = [
  {
    id: 1,
    name: 'Management',
    internal_id: 'productivity',
    taxable: false,
    commuter: false,
    description: 'Management training and skills.',
  },
  {
    id: 2,
    name: 'Technical',
    internal_id: 'tech',
    taxable: true,
    commuter: false,
    description: 'Technical skills development.',
  },
  {
    id: 3,
    name: 'Soft Skills',
    internal_id: 'tech',
    taxable: false,
    commuter: false,
    description: 'Soft skills improvement.',
  },
  {
    id: 4,
    name: 'Leadership',
    internal_id: 'productivity',
    taxable: false,
    commuter: false,
    description: 'Leadership training and mentoring.',
  },
  {
    id: 5,
    name: 'Finance',
    internal_id: 'productivity',
    taxable: true,
    commuter: false,
    description: 'Finance and budgeting courses.',
  },
  {
    id: 6,
    name: 'Communication',
    internal_id: 'remote-equipment',
    taxable: false,
    commuter: false,
    description: 'Communication skills training.',
  },
];

export const dummyCourseReviews: DevelopmentCourseReview[] = [
  {
    id: 1,
    author: {
      job_title: 'Software Engineer',
      industry: 'Technology',
      linkedin_account: 'https://linkedin.com/in/user1',
    },
    programReview: {
      review_title: 'Advanced React Development',
      review_description: `Deep dive into React hooks, context, and performance optimization. 
        Deep dive into React hooks, context, and performance optimization.`,
      course_url: 'https://www.skillshare.com',
      perk_categories: [perkCategories[1]],
      course_rating: 5,
      reactions: {total_reactions: 12, user_reaction: 'like'},
      is_approved: true,
      is_reported: false,
      reported_reason: null,
      moderation_notes: null,
      reviewed_by: {full_name: 'Jane Doe'},
    },
  },
  {
    id: 2,
    author: {job_title: 'Project Manager', industry: 'Finance', linkedin_account: null},
    programReview: {
      review_title: 'Leadership Essentials',
      review_description: `Lorem ipsum odor amet, consectetuer adipiscing elit. 
        Donec per nostra eros iaculis eu tincidunt netus maximus erat. 
        Netus ridiculus enim tristique; dignissim ullamcorper nec. 
        Ut sollicitudin cursus duis eros lacus non. Sociosqu varius 
        elementum sapien himenaeos dictum vestibulum iaculis dui magna. 
        Etiam morbi lacinia erat interdum cubilia.`,
      course_url: 'https://www.skillshare.com',
      perk_categories: [perkCategories[3]],
      course_rating: 4,
      reactions: {total_reactions: 20, user_reaction: 'love'},
      is_approved: true,
      is_reported: false,
      reported_reason: null,
      moderation_notes: null,
      reviewed_by: {full_name: 'John Smith'},
    },
  },
  {
    id: 3,
    author: {
      job_title: null,
      industry: 'Advertising',
      linkedin_account: 'https://linkedin.com/in/user3',
    },
    programReview: {
      review_title: 'Effective Communication Strategies',
      review_description: 'Master the art of persuasive communication in the workplace.',
      course_url: 'https://www.skillshare.com',
      perk_categories: [perkCategories[5], perkCategories[2]],
      course_rating: 3,
      reactions: {total_reactions: 8, user_reaction: 'insightful'},
      is_approved: true,
      is_reported: false,
      reported_reason: null,
      moderation_notes: null,
      reviewed_by: {full_name: 'Emily Davis'},
    },
  },
  {
    id: 4,
    author: {
      job_title: null,
      industry: 'Human Resources',
      linkedin_account: null,
    },
    programReview: {
      review_title: 'Conflict Resolution in Teams',
      review_description: 'Learn strategies to mediate and resolve workplace conflicts.',
      course_url: 'https://www.skillshare.com',
      perk_categories: [perkCategories[2], perkCategories[4]],
      course_rating: 5,
      reactions: {total_reactions: 15, user_reaction: 'like'},
      is_approved: true,
      is_reported: false,
      reported_reason: null,
      moderation_notes: null,
      reviewed_by: {full_name: 'Michael Brown'},
    },
  },
  {
    id: 5,
    author: {
      job_title: 'Data Analyst',
      industry: 'Technology',
      linkedin_account: 'https://linkedin.com/in/user5',
    },
    programReview: {
      review_title: 'Data Visualization Mastery',
      review_description: `Create stunning and impactful data visualizations with modern tools. 
        Create stunning and impactful data visualizations with modern tools.`,
      course_url: 'https://www.skillshare.com',
      perk_categories: [perkCategories[0], perkCategories[1]],
      course_rating: 4,
      reactions: {total_reactions: 18, user_reaction: null},
      is_approved: true,
      is_reported: false,
      reported_reason: null,
      moderation_notes: null,
      reviewed_by: {full_name: 'Sophia Martinez'},
    },
  },
  {
    id: 6,
    author: {
      job_title: 'Finance Consultant',
      industry: 'Banking',
      linkedin_account: 'https://linkedin.com/in/user6',
    },
    programReview: {
      review_title: 'Advanced Budgeting Techniques',
      review_description: 'Enhance your budgeting skills for corporate and personal finance.',
      course_url: 'https://www.skillshare.com',
      perk_categories: [perkCategories[4]],
      course_rating: 2,
      reactions: {total_reactions: 22, user_reaction: 'love'},
      is_approved: true,
      is_reported: false,
      reported_reason: null,
      moderation_notes: null,
      reviewed_by: {full_name: 'Alex Carter'},
    },
  },
];
