import axios, {AxiosResponse, AxiosError} from 'axios';
import {FileUploadFile} from '@compt/types/file-upload';

export const fetchSignedUrl = (
  file: FileUploadFile,
  callback: (data: {headUrl: string}) => void,
  onError: (error: string | AxiosError) => void,
  domain?: string,
  additionalParams?: object,
  token?: string,
) => {
  axios
    .get('/s3/sign', {
      params: {
        objectName: file.name,
        contentType: file.type,
        fileSize: file.size,
        domain: domain,
        ...(additionalParams ? additionalParams : {}),
      },
    })
    .then((data: AxiosResponse<{headUrl: string}>) => {
      callback(data.data);
    })
    .catch((error: AxiosError) => {
      if (onError) {
        onError(error);
      }
    });
};

export const getS3ObjectHead = (signedUrl: string) => axios.head(signedUrl);
