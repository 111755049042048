import axios, {AxiosError} from 'axios';
import {SecurityHelpers} from '@compt/utils/security-helpers';

export const getDefaultPutPostConfiguration = (
  additionalHeaders: {'Content-Type': string} | null = null,
) => {
  if (!additionalHeaders) {
    additionalHeaders = {} as {'Content-Type': string} | null;
  }

  return {
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'X-CSRFToken': SecurityHelpers.getCSRFToken(),
      ...additionalHeaders,
    },
  };
};

export const getFormDataConfiguration = () =>
  getDefaultPutPostConfiguration({
    'Content-Type': 'multipart/form-data; boundary=--3xyNe89--',
  });

export const logS3UploadError = (error: string | AxiosError | Record<string, unknown>) => {
  const bodyFormData = new FormData();

  let formattedError: string;

  if (typeof error === 'string') {
    formattedError = error;
  } else if (typeof error === 'object') {
    try {
      formattedError = JSON.stringify(error);
    } catch (e) {
      console.error('Error serializing error object:', e);
      formattedError = 'Unable to serialize error object';
    }
  } else {
    formattedError = 'Unknown error type';
  }

  bodyFormData.append('error', formattedError);

  return axios.post('/s3error/', bodyFormData, getFormDataConfiguration());
};
