import React from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Types
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {Controller, FieldPathByValue, FieldValues, PathValue} from 'react-hook-form';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptFormField} from '../compt-form-field/compt-form-field';

export interface ComptRatingFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  secret?: boolean;
  'data-testid'?: string;
  readOnly?: boolean;
  autoComplete?: string;
  maxLength?: number;
  watchedValue?: PathValue<TFieldValues, TName> | null | undefined;
}

export const ComptRatingField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptRatingFieldProps<TFieldValues, TName>,
) => {
  const field = (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <div className="flex border rounded-xl max-w-fit max-h-fit overflow-hidden">
          {[1, 2, 3, 4, 5].map((num) => (
            <span
              key={num}
              className={twMerge(
                `bg-white p-3 ${num !== 5 && 'border-r'}
          ${field.value === num && 'bg-gray-200 bg-opacity-100'}
          ${!props.disabled && 'hover:bg-surface-secondary-hover cursor-pointer'}`,
              )}
              onClick={() => {
                if (props.disabled) return;
                field.onChange(num);
              }}
            >
              <ComptSvgIcon
                iconName={`rating-${num}`}
                svgProp={{
                  width: '20px',
                  height: '20px',
                  filter: field.value !== num ? 'grayscale(80%)' : '',
                }}
              />
            </span>
          ))}
        </div>
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};
