import {comptApiSlice, ComptRTKTags} from './api-slice';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStipends: builder.query<any, void>({
      providesTags: [ComptRTKTags.ADMIN_STIPENDS],
      query: () => '/stipends',
    }),
  }),
});

export const {useGetStipendsQuery} = extendedComptApiSlice;
