// The only valid statuses to send to API endpoints
export enum PayloadLearningRequestStatus {
  PRE_APPROVAL_REQUESTED = 'PRE_APPROVAL_REQUESTED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  PRE_APPROVAL_REJECTED = 'PRE_APPROVAL_REJECTED',
  PRE_APPROVED = 'PRE_APPROVED',
  REIMBURSEMENT_REQUESTED = 'REIMBURSEMENT_REQUESTED',
  REIMBURSEMENT_REJECTED = 'REIMBURSEMENT_REJECTED',
  REIMBURSEMENT_APPROVED = 'REIMBURSEMENT_APPROVED',
  REIMBURSED = 'REIMBURSED',
  // Remove the below statuses in COMPT-5945
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

// Statuses that are only received from API endpoints
export enum ResponseLearningRequestStatus {
  APPROVED_BY_PRIMARY_APPROVER = 'APPROVED_BY_PRIMARY_APPROVER',
  APPROVED_BY_SECONDARY_APPROVER = 'APPROVED_BY_SECONDARY_APPROVER',
  APPROVED_BY_REIMBURSEMENT_APPROVER = 'APPROVED_BY_REIMBURSEMENT_APPROVER',
}

export type LearningRequestStatus = PayloadLearningRequestStatus | ResponseLearningRequestStatus;

// Total list of statuses that can be displayed
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LearningRequestStatus = {
  ...PayloadLearningRequestStatus,
  ...ResponseLearningRequestStatus,
};
