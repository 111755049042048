import React from 'react';

// Components
import {LndDropDown} from '@compt/pages/admin-pages/admin-review-lnd/components/review-lnd-dropdown';

const FILTER_OPTIONS = [
  {id: 'industry', label: 'Industry', testId: 'community-feed-industry-filter'},
  {id: 'category', label: 'Category', testId: 'community-feed-category-filter'},
];

export const CommunityFeedSidebar: React.FC = () => (
  <div className="flex flex-col p-4 flex-grow">
    <div className="flex justify-between items-center">
      <p className="text-md text-color-body1 font-bold">Filter</p>
    </div>
    <div className="flex flex-col mt-4 space-y-2">
      {FILTER_OPTIONS.map((filter) => (
        <React.Fragment key={filter.id}>
          <LndDropDown
            id={`review-lnd-${filter.id}-filter`}
            data-testid={filter.testId}
            value={{id: filter.id, label: filter.label}}
            onClick={() => {}}
            isSelected={false}
          />
        </React.Fragment>
      ))}
    </div>
    <hr className="my-4 mr-4 border-gray-300" />
    <label key="my-company-only-community-feed" className="flex items-center space-x-1">
      <input
        type="checkbox"
        className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
                        focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
                        disabled:hover:bg-gray-400`}
      />
      <span className="text-md text-gray-700">Only my company</span>
    </label>
  </div>
);
