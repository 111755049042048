import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {twMerge} from 'tailwind-merge';

interface LndDropDownProps {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  onClick: () => void;
  isSelected: boolean;
}

export const LndDropDown = (props: LndDropDownProps) => {
  const {id, value, title, placeholder, disabled} = props;

  return (
    <div className="w-full min-w-[10rem]">
      {title && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
          {title}
        </label>
      )}
      <button
        id={id}
        disabled={disabled}
        className={twMerge(`
          flex items-center justify-between w-full 
          bg-white pr-3 py-0 text-left text-md text-gray-700 focus:outline-none
          ${disabled ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer'}
        `)}
        onClick={props.onClick}
      >
        <span className={`truncate ${!value ? 'text-gray-400' : ''}`}>
          {value?.label || placeholder || 'Select...'}
        </span>
        <ComptSvgIcon
          iconName={props.isSelected ? 'chevron-up-icon-gray' : 'chevron-down-icon-gray'}
          className="ml-2"
          svgProp={{width: '20px', height: '20px'}}
        />
      </button>
    </div>
  );
};
