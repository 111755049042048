import React from 'react';

// Components
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptNumberField} from '@compt/common/forms/compt-number-field/compt-number-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

// Types
import {UseFormReturn} from 'react-hook-form';

interface AdminMileageBEFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any, any, undefined>;
  isReadOnly: boolean;
}

export const AdminMileageExpenseFields = ({formMethods, isReadOnly}: AdminMileageBEFieldProps) => (
  <>
    <ComptDatePickerField
      name="start_date"
      label="Start date"
      data-testid="start-date-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Start date is required'}}
      errors={formMethods.formState.errors.start_date}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptDatePickerField
      name="end_date"
      label="End date"
      data-testid="end-date-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'End date is required'}}
      errors={formMethods.formState.errors.end_date}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptTextField
      name="origin"
      label="Origin"
      data-testid="origin-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Origin is required'}}
      errors={formMethods.formState.errors.origin}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptTextField
      name="destination"
      label="Destination"
      data-testid="destination-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Destination is required'}}
      errors={formMethods.formState.errors.destination}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptNumberField
      name="distance"
      label="Distance (miles)"
      data-testid="distance-field"
      placeholder="ex. 265.5"
      stepValue=".1"
      decimalPlaces={1}
      control={formMethods.control}
      register={formMethods.register}
      // eslint-disable-next-line eqeqeq
      validation={{required: 'Distance is required', validate: (value) => value != 0}}
      errors={formMethods.formState.errors.distance}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
  </>
);
