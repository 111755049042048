import React from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {formatCurrency} from '@compt/utils/international-helpers';

// Types
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

interface ReviewLndComputedSectionProps {
  request: PreApprovalRequest;
}

export const ReviewLndComputedSection = (props: ReviewLndComputedSectionProps) => {
  const {request} = props;
  const requestAmountCurrency = request.program_budget.program_funding_rule.currency;
  const amountAvailable =
    getAsNumber(request.program_budget.amount) -
    getAsNumber(request.program_budget.amount_committed);

  const overBudgetCalculation = calculateOverBudget(amountAvailable, request.request_data.Amount);
  const isOverBudget = overBudgetCalculation > 0;

  function getAsNumber(amount: number | string) {
    return typeof amount === 'string' ? parseFloat(amount) : amount;
  }

  function calculateOverBudget(amountAvailable: number | string, amountSubmitted: number | string) {
    const convertedAmountAvailable = getAsNumber(amountAvailable);
    const convertedAmountSubmitted = getAsNumber(amountSubmitted);

    return Math.max(0, convertedAmountSubmitted - convertedAmountAvailable);
  }

  return (
    <div className="grid gap-y-2 mb-8">
      <div className="flex justify-between">
        <p>Available budget</p>
        <p className="label1">{formatCurrency(amountAvailable, requestAmountCurrency)}</p>
      </div>
      <div className="flex justify-between">
        <p>Over budget</p>
        <p className={twMerge(`label1 ${isOverBudget && 'text-color-error'}`)}>
          {formatCurrency(overBudgetCalculation, requestAmountCurrency)}
        </p>
      </div>
      {isOverBudget && (
        <div className="body2 text-color-error">You may still approve this budget.</div>
      )}
    </div>
  );
};
