import Cookies from 'js-cookie';

const CSRF_TOKEN_COOKIE_NAME = 'csrftoken';
const getCSRFToken: () => string = () => Cookies.get(CSRF_TOKEN_COOKIE_NAME) || '';

/* eslint-disable no-useless-escape */
const passwordRegex = /^(?=.{8,})(?=.*[a-z,A=Z])(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]).*$/;

export const SecurityHelpers = {
  getCSRFToken,
  passwordRegex,
};
