import React, {HTMLAttributes} from 'react';
import {
  SupportedCountriesType,
  formatCurrencyFromCountryCode,
} from '@compt/utils/international-helpers';
import {Allotment} from '@compt/types/allotments';
import {ComptProgressBar} from '@compt/common/compt-progress-bar/compt-progress-bar';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

export interface ComptSpentTeamRecognitionCardProps extends HTMLAttributes<HTMLElement> {
  allotment: Allotment;
  userCountryCode?: SupportedCountriesType;
  isMobileView?: boolean;
}

export const ComptSpentTeamRecognitionCard = (props: ComptSpentTeamRecognitionCardProps) => {
  const isMobileView = useIsMobileView();
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row mb-400 sm:mb-000 sm:mt-000 sm:pb-300">
        {isMobileView ? (
          <>
            <p className="heading1">
              {formatCurrencyFromCountryCode(
                props.allotment.balance_amount,
                props.userCountryCode,
                props.allotment.currency,
              )}
              &nbsp;
            </p>
            <p className="heading3 font-semibold">to give</p>
          </>
        ) : (
          <>
            <p className="heading1">
              {formatCurrencyFromCountryCode(
                props.allotment.balance_amount,
                props.userCountryCode,
                props.allotment.currency,
              )}
              &nbsp;
            </p>
            <p className="heading3 font-semibold">to give</p>
          </>
        )}
      </div>
      <ComptProgressBar
        className="pb-100"
        percentage={
          100 -
          (props.allotment.amount_spent /
            (props.allotment.balance_amount + props.allotment.amount_spent)) *
            100
        }
      />
      <div className="flex sm:justify-end">
        <p className="label2 text-color-body1">
          {formatCurrencyFromCountryCode(
            props.allotment.amount_spent,
            props.userCountryCode,
            props.allotment.currency,
          )}
          &nbsp;given&nbsp;/&nbsp;
          {formatCurrencyFromCountryCode(
            props.allotment.balance_amount + props.allotment.amount_spent,
            props.userCountryCode,
            props.allotment.currency,
          )}
        </p>
      </div>
    </div>
  );
};
