import React from 'react';
import {
  formatCurrency,
  getSupportedCountryInfo,
  SupportedCountriesType,
} from '@compt/utils/international-helpers';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {StipendStatProperties} from '@compt/types/allotments';
import {ComptTooltip} from '@compt/common/compt-tooltip/compt-tooltip.container';
import {ComptCard} from '@compt/common/compt-card/compt-card';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

export interface ComptStipendStatCardProps extends StipendStatProperties {
  userCountryCode: SupportedCountriesType;
  helpText?: string;
  doodleName?: string;
}

const getStatValue = (
  stat: number | Record<string, number>,
  userCountryCode: SupportedCountriesType,
) => {
  if (typeof stat === 'number') {
    return stat;
  }

  if (Object.keys(stat).length === 1) {
    // eslint-disable-next-line prefer-destructuring
    const [currency, amount] = Object.entries(stat)[0];
    return formatCurrency(amount, currency);
  }

  const country = getSupportedCountryInfo(userCountryCode as SupportedCountriesType);
  const amount = stat[country.currency];

  /**
   * Handles empty stat object and stat object with no key for current currency
   */
  if (Object.keys(stat).length === 0 || !Object.keys(stat).includes(country.currency)) {
    return formatCurrency(0, country.currency);
  }

  return formatCurrency(amount, country.currency);
};

export const StipendStatCard = (props: ComptStipendStatCardProps) => {
  const isMobileView = useIsMobileView();

  return (
    <ComptCard className="overflow-hidden">
      <div className="compt-text-card w-full sm:min-w-[276px] pl-300 pt-300 sm:pl-400 sm:t-400">
        <div className="flex flex-row items-center mb-100 sm:mb-000 pb-000 sm:pb-200">
          <p className="label4 text-color-body2">{props.title}</p>
          {!isMobileView && props.helpText && (
            <ComptTooltip message={<p className="compt-text--xs text-white">{props.helpText}</p>}>
              <ComptSvgIcon
                iconName="help-circle"
                labelHidden={false}
                ariaLabel="help-circle"
                className="ml-050 w-[15px] h-[15px]"
                svgProp={{width: '15px', height: '15px', stroke: '#475467'}}
              />
            </ComptTooltip>
          )}
        </div>
        <p className="heading1">{getStatValue(props.stat, props.userCountryCode)}</p>
        {props.doodleName && (
          <ComptSvgIcon iconName={props.doodleName} className="w-full flex justify-end" />
        )}
      </div>
    </ComptCard>
  );
};
