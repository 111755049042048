import React from 'react';

// Hooks and methods
import {useGroupTableContext} from './compt-group-table.context';
import {Control, useFormContext, UseFormReturn} from 'react-hook-form';
import {useConfirmationModal} from '../../utils/confirmation-modal-helper';

// Types
import {GroupTableFieldValues} from './compt-group-table.types';
import {GroupTypeOption, OptionType} from '@compt/types/user-groups/user-groups';

// Components
import {GroupTableDropdown} from './compt-group-table.dropdown';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

interface GroupTableHeaderProps {
  amountHeader: string;
}

export const GroupTableHeaders = (props: GroupTableHeaderProps) => {
  const {maxNumOfColumns, columnFieldMethods, rowFieldMethods, groupTypeOptions} =
    useGroupTableContext();
  const formMethods: UseFormReturn<GroupTableFieldValues> = useFormContext();

  const {showModal, modal} = useConfirmationModal(
    'By changing this group, all selected options underneath will be reset.',
  );

  const {showModal: showEveryoneModal, modal: everyoneModal} = useConfirmationModal(
    'By changing this to Everyone, all data in this budget plan will be reset.',
  );

  /**
   * Returns group type options that have not yet been selected
   */
  function getAvailableOptions(): GroupTypeOption[] {
    const selectedColumns = formMethods.getValues('selectedColumns');
    const selectedGroupTypeValues = selectedColumns.map((col: GroupTypeOption) => col.key);
    const availableOptions = groupTypeOptions.filter(
      (option) => !selectedGroupTypeValues.includes(option.key) && option.type !== OptionType.DATE,
    );

    return availableOptions;
  }

  function onColumnAdd() {
    columnFieldMethods.append({key: null});
  }

  function onColumnDelete(columnToDelete: number, columnId: string) {
    // Do not allow column deletion when only one column exists
    if (columnFieldMethods.fields.length === 1) return;

    // Remove the column id from every existing row
    rowFieldMethods.fields.forEach((_, rowIndex) => {
      const currentRowValue = formMethods.getValues('amountOverrides')?.[rowIndex];
      delete currentRowValue?.overrides[columnId];

      rowFieldMethods.update(rowIndex, {...currentRowValue});
    });

    columnFieldMethods.remove(columnToDelete);
  }

  function onColumnChange(fieldId: string) {
    return async function (
      onFieldChange: (event: GroupTypeOption[]) => void,
      event: GroupTypeOption[],
    ) {
      // Handle case where "everyone" is selected as an option
      if ('key' in event && event.key === 'everyone') {
        const confirmEveryoneOption = await showEveryoneModal();

        if (confirmEveryoneOption) {
          onFieldChange(event);
        }

        return;
      }

      // Handle case where group values exist for the selected column
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Unreachable code error
      const groupValuesExist = rowFieldMethods.fields.some((field) => field.overrides?.[fieldId]);

      if (groupValuesExist) {
        const confirmChange = await showModal();
        if (!confirmChange) return;
      }

      onFieldChange(event);

      if (rowFieldMethods.fields.length === 0) return;

      // Resets any group selections associated with a column, when the column group type changes
      rowFieldMethods.fields.forEach((_, rowIndex) => {
        const currentValue = formMethods.getValues('amountOverrides')?.[rowIndex];
        rowFieldMethods.update(rowIndex, {
          ...currentValue,
          overrides: {...currentValue.overrides, [fieldId]: null},
        });
      });
    };
  }

  return (
    <>
      {modal}
      {everyoneModal}
      <div className="flex border-b h-14">
        {columnFieldMethods.fields.map((field, index) => (
          <GroupTableDropdown
            name={`selectedColumns.${index}`}
            control={formMethods.control as Control<GroupTableFieldValues>}
            key={field.id}
            data-testid={`group-type-select-col-${index}`}
            placeholder="Select a group"
            options={getAvailableOptions()}
            getKey={(option) => option.key}
            getDisplayText={(option) => option?.header}
            textClassName="label4"
            onDelete={() => onColumnDelete(index, field.id)}
            showDeleteOnHover={columnFieldMethods.fields.length > 1}
            onDropdownChange={onColumnChange(field.id)}
          />
        ))}
        <div className="w-full">
          <p className="label4 h-14 flex items-center pl-300">{props.amountHeader}</p>
        </div>
        <div data-testid="add-group-type-button" className="flex items-center h-14 px-400 border-l">
          {columnFieldMethods.fields.length < maxNumOfColumns ? (
            <div className="cursor-pointer" onClick={onColumnAdd}>
              <ComptSvgIcon iconName="plus-icon-blue" svgProp={{width: '24px', height: '24px'}} />
            </div>
          ) : (
            <div className="w-6" />
          )}
        </div>
      </div>
    </>
  );
};
