import React from 'react';

// Components
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

// Types
import {UseFormReturn} from 'react-hook-form';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

interface AdminStandardBEFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any>;
  isReadOnly: boolean;
}

export const AdminStandardBusinessExpenseFields = ({
  formMethods,
  isReadOnly,
}: AdminStandardBEFieldProps) => (
  <>
    <ComptDatePickerField
      name="receipt_date"
      label="Receipt date"
      data-testid="receipt-date-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{required: 'Receipt date is required'}}
      errors={formMethods.formState.errors.receipt_date}
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
    <ComptTextField
      name="vendor"
      label="Vendor"
      data-testid="vendor-field"
      control={formMethods.control}
      register={formMethods.register}
      validation={{
        required: 'Vendor is required',
        maxLength: {
          value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
          message: `Vendor has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
        },
      }}
      errors={formMethods.formState.errors.vendor}
      disabled={isReadOnly}
      readOnly={isReadOnly}
      maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
      watchedValue={formMethods.watch('vendor')}
    />
    <ComptCurrencyField
      name="amount"
      label="Amount"
      data-testid="amount-field"
      control={formMethods.control}
      register={formMethods.register}
      errors={formMethods.formState.errors.amount}
      validation={{
        required: 'Amount is required',
        min: 0.01,
      }}
      placeholder="0.00"
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
  </>
);
