import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {BaseIconName} from './compt-side-nav-button';

const isAdminNDSEnabled =
  featureEnabled(FeatureFlags.ENABLE_ADMIN_NDS) &&
  !featureEnabled(FeatureFlags.USER_DISABLED_NEW_REACT_APP);

export enum SideNavItemKeys {
  // Internal Admin Section
  DJANGO_ADMIN = 'DJANGO_ADMIN',
  COMPT_ADMIN = 'COMPT_ADMIN',

  // Employee Section
  MY_STIPEND = 'MY_STIPEND',
  BUSINESS_EXPENSE = 'BUSINESS_EXPENSE',
  TEAM_RECOGNITION = 'TEAM_RECOGNITION',
  ACTIVITY = 'ACTIVITY',
  EXPLORE = 'EXPLORE',
  FAQ = 'FAQ',
  ASSIGNMENTS = 'ASSIGNMENTS',
  LEARNING = 'LEARNING',
  COMMUNITY = 'COMMUNITY',

  // Stipend Admin Section
  STIPEND_ADMIN_DASHBOARD = 'STIPEND_ADMIN_DASHBOARD',
  STIPEND_ADMIN_STIPENDS = 'STIPEND_ADMIN_STIPENDS',
  STIPEND_ADMIN_TEAM = 'STIPEND_ADMIN_TEAM',
  STIPEND_ADMIN_TEAM_SYNC_LOGS = 'STIPEND_ADMIN_TEAM_SYNC_LOGS',
  STIPEND_ADMIN_PAYROLL = 'STIPEND_ADMIN_PAYROLL',
  STIPEND_ADMIN_FAQ = 'STIPEND_ADMIN_FAQ',
  STIPEND_ADMIN_COMPANY_SETTINGS = 'STIPEND_ADMIN_COMPANY_SETTINGS',
  STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS = 'STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS',

  // Business Expense Admin Section
  BE_ADMIN_REPORTING = 'BE_ADMIN_REPORTING',
  BE_ADMIN_TEAM = 'BE_ADMIN_TEAM',
  BE_ADMIN_REVIEW_EXPENSES = 'BE_ADMIN_REVIEW_EXPENSES',
  BE_ADMIN_SEARCH_EXPENSES = 'BE_ADMIN_SEARCH_EXPENSES',
  BE_ADMIN_PAYROLL = 'BE_ADMIN_PAYROLL',
  BE_ADMIN_SETTINGS = 'BE_ADMIN_SETTINGS',
}

export interface SideNavItemType {
  key: string;
  title: string;
  iconId?: BaseIconName;
  pagePath: string;
  /**
   * Determines whether this should be a server path for use within an HTML 'a' tag.
   */
  isHref?: boolean;
}
export const SideNavItem: Record<SideNavItemKeys | string, SideNavItemType> = {
  // Internal Admin Section
  DJANGO_ADMIN: {
    key: SideNavItemKeys.DJANGO_ADMIN,
    title: 'Django admin',
    iconId: BaseIconName.PIE_CHART,
    pagePath: '/admin',
    isHref: true,
  },
  COMPT_ADMIN: {
    key: SideNavItemKeys.COMPT_ADMIN,
    title: 'Compt admin',
    iconId: BaseIconName.PIE_CHART,
    pagePath: '/superboard',
    isHref: true,
  },

  // Employee Section
  // Deprecated: migrated to compt-layout.controller.tsx
  MY_STIPEND: {
    key: SideNavItemKeys.MY_STIPEND,
    title: 'My stipends',
    iconId: BaseIconName.BANK_NOTE,
    pagePath: '/',
  },
  BUSINESS_EXPENSE: {
    key: SideNavItemKeys.BUSINESS_EXPENSE,
    title: 'Business expenses',
    iconId: BaseIconName.BRIEFCASE,
    pagePath: '/business-expenses',
  },
  TEAM_RECOGNITION: {
    key: SideNavItemKeys.TEAM_RECOGNITION,
    title: 'Team recognition',
    iconId: BaseIconName.AWARD,
    pagePath: '/team-recognition',
  },
  ACTIVITY: {
    key: SideNavItemKeys.ACTIVITY,
    title: 'Stipend activity',
    iconId: BaseIconName.ACTIVITY,
    pagePath: '/activity',
  },
  EXPLORE: {
    key: SideNavItemKeys.EXPLORE,
    title: 'Explore',
    iconId: BaseIconName.SEARCH,
    pagePath: '/explore',
  },
  FAQ: {
    key: SideNavItemKeys.FAQ,
    title: 'FAQs',
    iconId: BaseIconName.ANNOTATION_QUESTION,
    pagePath: '/faq',
  },
  LEARNING: {
    key: SideNavItemKeys.LEARNING,
    title: 'Learning',
    iconId: BaseIconName.GRADUATION_CAP,
    pagePath: '/learning',
  },
  ASSIGNMENTS: {
    key: SideNavItemKeys.ASSIGNMENTS,
    title: 'Assignments',
    iconId: BaseIconName.CHECK_DONE,
    pagePath: '/accounts/delegate',
  },

  // Stipend Admin Section
  STIPEND_ADMIN_DASHBOARD: {
    key: SideNavItemKeys.STIPEND_ADMIN_DASHBOARD,
    title: 'Dashboard',
    iconId: BaseIconName.BAR_CHART,
    pagePath: '/dashboard',
    isHref: true,
  },
  STIPEND_ADMIN_STIPENDS: {
    key: SideNavItemKeys.STIPEND_ADMIN_STIPENDS,
    title: 'Stipends',
    iconId: BaseIconName.BANK_NOTE,
    pagePath: '/manage/#/stipends',
    isHref: true,
  },
  STIPEND_ADMIN_TEAM: {
    key: SideNavItemKeys.STIPEND_ADMIN_TEAM,
    title: 'Team',
    iconId: BaseIconName.USERS,
    pagePath: '/company/team',
    isHref: true,
  },
  STIPEND_ADMIN_TEAM_SYNC_LOGS: {
    key: SideNavItemKeys.STIPEND_ADMIN_TEAM_SYNC_LOGS,
    title: 'Team sync logs',
    iconId: BaseIconName.USERS,
    pagePath: '/app/#/sync-attempts/',
    isHref: true,
  },
  STIPEND_ADMIN_PAYROLL: {
    key: SideNavItemKeys.STIPEND_ADMIN_PAYROLL,
    title: 'Payroll',
    iconId: BaseIconName.COIN_SWAP,
    pagePath: '/payroll/summary',
    isHref: true,
  },
  STIPEND_ADMIN_FAQ: {
    key: SideNavItemKeys.STIPEND_ADMIN_FAQ,
    title: 'Manage FAQs',
    iconId: BaseIconName.ANNOTATION_QUESTION,
    pagePath: '/company/faqs',
    isHref: true,
  },
  STIPEND_ADMIN_COMPANY_SETTINGS: {
    key: SideNavItemKeys.STIPEND_ADMIN_COMPANY_SETTINGS,
    title: 'Company settings',
    iconId: BaseIconName.SETTINGS,
    pagePath: '/company/settings',
    isHref: true,
  },
  STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS: {
    key: SideNavItemKeys.STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS,
    title: 'Notifications',
    iconId: BaseIconName.PIE_CHART,
    pagePath: '/manage/#/customizable-notifications',
    isHref: true,
  },

  // Business Expense Admin Section
  BE_ADMIN_REPORTING: {
    key: SideNavItemKeys.BE_ADMIN_REPORTING,
    title: 'Reporting',
    iconId: BaseIconName.BAR_CHART,
    pagePath: '/business_expense_dashboard',
    isHref: true,
  },
  BE_ADMIN_TEAM: {
    key: SideNavItemKeys.BE_ADMIN_TEAM,
    title: 'Team',
    iconId: BaseIconName.USERS,
    pagePath: '/company/team',
    isHref: true,
  },
  // Deprecated: migrated to compt-layout.controller.tsx
  BE_ADMIN_REVIEW_EXPENSES: {
    key: SideNavItemKeys.BE_ADMIN_REVIEW_EXPENSES,
    title: 'Review expenses',
    iconId: BaseIconName.CHECK_DONE,
    pagePath: isAdminNDSEnabled
      ? '/review-business-expenses'
      : '/manage/#/review-business-expenses',
    isHref: true,
  },
  // Deprecated: migrated to compt-layout.controller.tsx
  BE_ADMIN_SEARCH_EXPENSES: {
    key: SideNavItemKeys.BE_ADMIN_SEARCH_EXPENSES,
    title: 'Search expenses',
    iconId: BaseIconName.SEARCH_REFRACTION,
    pagePath: '/manage/#/search-expenses',
    isHref: true,
  },
  BE_ADMIN_PAYROLL: {
    key: SideNavItemKeys.BE_ADMIN_PAYROLL,
    title: 'Payroll',
    iconId: BaseIconName.COIN_SWAP,
    pagePath: '/payroll/summary',
    isHref: true,
  },
  BE_ADMIN_SETTINGS: {
    key: SideNavItemKeys.BE_ADMIN_SETTINGS,
    title: 'Expense settings',
    iconId: BaseIconName.SETTINGS,
    pagePath: '/business_expenses/settings/',
    isHref: true,
  },
};
