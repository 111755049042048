import React, {useRef} from 'react';

// Hooks and methods
import {FieldValues, useForm} from 'react-hook-form';

// Types
import {Comment} from '@compt/types/comments/comment';
import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

// Components
import {ComptReceivedComment, ComptSentComment} from './compt-comment';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptLoadingAnimation} from '../compt-loading/compt-loading-animation';

export interface CommentFieldValues extends FieldValues {
  comment_input: string;
}

export interface CommentBoxProps {
  messages: Comment[];
  onClickSend?: (
    form: CommentFieldValues,
  ) => Promise<{data: Comment} | {error: FetchBaseQueryError | SerializedError}>;
  onRefreshFeedClicked?: () => void;
  isLoading?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
}

export const ComptCommentBox = (props: CommentBoxProps) => {
  const {messages, isLoading} = props;

  const formMethods = useForm<CommentFieldValues>();
  const watchedCommentInput = formMethods.watch('comment_input');

  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  async function handleSubmit(form: CommentFieldValues) {
    if (props.onClickSend) {
      props.onClickSend(form).then((results) => {
        if ('error' in results) {
          return;
        }

        formMethods.resetField('comment_input');
        scrollToBottom();
      });
    }
  }

  return (
    <div className="flex flex-col w-full h-full border">
      <div
        onScroll={props.onScroll}
        id="Commentbox"
        className="flex w-full overflow-y-auto gap-y-4 py-5 px-6 flex-1 flex-col-reverse basis-0"
      >
        {/* Chat end ref is at the top due to flex-col-reverse */}
        <div ref={chatEndRef} />
        {props.onRefreshFeedClicked ? (
          <div className="flex flex-col items-center">
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              disabled={isLoading}
              onClick={props.onRefreshFeedClicked}
              className="shadow-lg"
            >
              Refresh feed
            </ComptButton>
          </div>
        ) : null}
        {messages && messages.length > 0
          ? messages.map((message) => {
              if (message.is_author) {
                return (
                  <ComptSentComment
                    key={message.id}
                    name={message.author.full_name}
                    date={message.created_on}
                    message={message.message}
                    messageType={message.comment_type}
                    status={message.new_status}
                  />
                );
              }

              return (
                <ComptReceivedComment
                  key={message.id}
                  name={message.author.full_name}
                  date={message.created_on}
                  message={message.message}
                  messageType={message.comment_type}
                  status={message.new_status}
                />
              );
            })
          : null}
        {isLoading && (
          <div className="flex flex-col items-center mt-6">
            <ComptLoadingAnimation />
            <p className="label3 text-color-body1 mt-2">Loading comments...</p>
          </div>
        )}
        {!isLoading && messages && messages.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full">
            <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
            <p className="heading4 text-center" />
            No comments exist yet!
          </div>
        ) : null}
      </div>
      <form
        className="flex w-full items-center px-6 py-4 border-t bg-surface-background"
        onSubmit={formMethods.handleSubmit(handleSubmit)}
      >
        <input
          className={`w-full border-stroke-divider1 rounded-lg mr-4 ring-stroke-divider1
             focus:border-stroke-focus focus:ring-1 focus:ring-stroke-focus`}
          type="text"
          placeholder="Type your message..."
          autoComplete="off"
          {...formMethods.register('comment_input')}
        />
        <button
          type="submit"
          disabled={!watchedCommentInput || watchedCommentInput.trim() === ''}
          className={`rounded-full bg-stroke-primary p-3 active:opacity-90 
          ring-inset ring-1 focus:ring-stroke-focus disabled:bg-stroke-disabled
          disabled:ring-stroke-disabled`}
        >
          <ComptSvgIcon iconName="paper-plane-icon" />
        </button>
      </form>
    </div>
  );
};
