import React, {useState} from 'react';

// Components
import {
  ComptConfirmationModal,
  ModalType,
} from '../common/compt-confirmation-modal/compt-confirmation.modal';
import {ComptButton, ComptButtonType} from '../common/compt-button/compt-button';

export const useConfirmationModal = (
  customText: string,
  title?: string,
  modalType?: ModalType,
  customButtonText?: string,
  customCancelButtonText?: string,
) => {
  const [modalVisible, setModalVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [modalPromise, setModalPromise] = useState<any>(null);

  const buttonType =
    modalType === ModalType.DESTRUCTIVE ? ComptButtonType.DESTRUCTIVE : ComptButtonType.PRIMARY;

  function showModal() {
    return new Promise<boolean>((resolve) => {
      setModalVisible(true);
      setModalPromise(() => resolve);
    });
  }

  function handleConfirm() {
    modalPromise(true);
    setModalVisible(false);
  }

  function handleCancel() {
    modalPromise(false);
    setModalVisible(false);
  }

  const modal = (
    <ComptConfirmationModal
      title={title ?? 'Confirm changes'}
      content={customText}
      open={modalVisible}
      setOpen={handleCancel}
      modalType={modalType ?? ModalType.PRIMARY}
    >
      <div className="flex flex-row space-x-2 justify-end">
        <ComptButton buttonType={ComptButtonType.OUTLINED} onClick={handleCancel}>
          {customCancelButtonText ?? 'Cancel'}
        </ComptButton>
        <ComptButton buttonType={buttonType} onClick={handleConfirm}>
          {customButtonText ?? 'Continue'}
        </ComptButton>
      </div>
    </ComptConfirmationModal>
  );

  return {showModal, modal};
};
