import {DateString} from '../common/date-string';
import {ListResultsBase} from '../common/list-results';
import {LearningRequestStatus} from '../learning-development/learning-request-status';

export enum MessageType {
  USER_COMMENT = 'USER_COMMENT',
  STATUS_CHANGE = 'STATUS_CHANGE',
}

export interface Author {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
}

export interface Comment {
  id: number;
  author: Author;
  comment_type: MessageType;
  new_status: LearningRequestStatus | null;
  is_author: boolean;
  message: string | null;
  created_on: DateString;
}

export type CommentListResults = ListResultsBase<Comment>;
