import React, {HTMLAttributes, useState} from 'react';
import {Allotment} from '@compt/types/allotments';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {CardExpirationText} from '@compt/common/compt-card/expiration-text';
import {ComptCard} from '@compt/common/compt-card/compt-card';
import {PerkCategory, PerkCategoryCustomizations} from '@compt/types/perk-category';
import {getAccrualDescription, getAllotmentCycleTypeName} from '@compt/utils/allotment-helpers';
import {CardExpirationIndicator} from '@compt/common/compt-card/expiration-indicator';
import {ComptSpentCard} from '@compt/common/compt-card/compt-spent-card';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {MobileViewDetailsModal} from './mobile-view-details-modal';
import DOMPurify from 'dompurify';

export interface MobileAllotmentCardProps extends HTMLAttributes<HTMLElement> {
  allotment: Allotment;
  categoryCustomizations: PerkCategoryCustomizations[] | undefined;
  userCountryCode?: SupportedCountriesType;
  onCategoryClick: (category: PerkCategory, allotment: Allotment) => void;
}

export const MobileAllotmentCard = (props: MobileAllotmentCardProps) => {
  const [openViewDetails, setOpenViewDetails] = useState(false);
  return (
    <>
      {openViewDetails && (
        <MobileViewDetailsModal
          open={openViewDetails}
          setOpen={setOpenViewDetails}
          allotment={props.allotment}
          userCountryCode={props.userCountryCode}
          categoryCustomizations={props.categoryCustomizations}
          onCategoryClick={props.onCategoryClick}
        />
      )}
      <ComptCard>
        <div className="p-300 border-b border-b-gray-300">
          <h2 className="text-color-body1">{props.allotment.cycle.name}</h2>
          <CardExpirationIndicator allotment={props.allotment} />
        </div>
        <div className="px-300 pt-300 pb-400">
          <div>
            <div>
              <ComptSpentCard
                amountTotal={props.allotment.maximum_balance}
                amountSpent={props.allotment.amount_spent}
                currency={props.allotment.currency}
                userCountryCode={props.userCountryCode}
                reversed
              />
              <CardExpirationText
                expirationDate={props.allotment.last_date}
                cycleTypeDescription={getAllotmentCycleTypeName(props.allotment.cycle)}
                accrualDescription={getAccrualDescription(props.allotment.cycle)}
              />
            </div>
            {props.allotment.cycle.description && (
              <div className="mt-100">
                <p
                  className="body3 text-color-body1 line-clamp-3 inner-html"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.allotment.cycle.description),
                  }}
                />
              </div>
            )}
          </div>
          <div className="mt-300">
            <ComptLink
              link="#"
              onClick={() => {
                setOpenViewDetails(true);
              }}
            >
              <p className="flex label3 text-color-link">
                View details
                <ComptSvgIcon iconName="chevron-right-icon" />
              </p>
            </ComptLink>
          </div>
        </div>
      </ComptCard>
    </>
  );
};
